<div class="content-title">
  {{org.name}} Team
  <button mat-raised-button class="button invite-button tw-shrink-0" (click)="showInviteDialog()">
    <div class="invite-button-text tw-flex tw-items-center tw-gap-2">
      <mat-icon class="tw-font-bold">add</mat-icon>
      Invite Users
    </div>
  </button>
</div>
<div class="team-tabs">
  <mat-tab-group class="mat-tab-fill-height">
    <mat-tab label="Users">
      <app-team-users [canAdmin]="canAdmin" [org]="org"></app-team-users>
    </mat-tab>
    <mat-tab label="Invitations">
      <ng-template matTabContent>
        <app-team-invitations [canAdmin]="canAdmin" [org]="org"></app-team-invitations>
      </ng-template>
    </mat-tab>
    <mat-tab label="Service Accounts">
      <ng-template matTabContent>
        <app-team-service-accounts [canAdmin]="canAdmin" [org]="org"></app-team-service-accounts>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
