import { Component, Inject, ViewChild } from '@angular/core';
import { OrgSettingsEditorComponent } from './org-settings-editor.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateOrgSettingsRequest } from 'ldt-identity-service-api';

// settings-modal.component.ts
@Component({
  template: `
      <div class="tw-p-4">
        <mat-dialog-content>
          <app-org-settings-editor 
            [usePartialUpdate]="usePartialUpdate"
            [initialSettings]="initialSettings"
            #settingsForm>
          </app-org-settings-editor>
        </mat-dialog-content>
  
        <mat-dialog-actions align="end" class="tw-mt-6 tw-space-x-2">
          <button mat-button (click)="onCancel()">Cancel</button>
          <button mat-raised-button color="primary" (click)="onSave()">
            Save Changes
          </button>
        </mat-dialog-actions>
      </div>
    `,
})
export class OrgSettingsEditorModalComponent {
  @ViewChild('settingsForm') settingsForm!: OrgSettingsEditorComponent;
  usePartialUpdate: boolean;
  initialSettings?: UpdateOrgSettingsRequest;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      usePartialUpdate: boolean;
      initialSettings?: UpdateOrgSettingsRequest;
    },
    private dialogRef: MatDialogRef<OrgSettingsEditorModalComponent>
  ) {
    this.usePartialUpdate = data.usePartialUpdate;
    this.initialSettings = data.initialSettings;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const settings = this.settingsForm.getCurrentSettings();
    this.dialogRef.close(settings);
  }
}
