import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification-service/notification.service';
import { prebuiltLists } from './prebuilt-lists';
import { SavedList } from './saved-lists-models';

@Injectable({
  providedIn: 'root',
})
export class SavedListsService {
  private listsSource = new BehaviorSubject<SavedList[] | null>(null);
  private refreshing = new BehaviorSubject<boolean>(false);

  lists$ = this.listsSource.asObservable();
  refreshing$ = this.refreshing.asObservable();

  constructor(private notify: NotificationService) {
    this.getLists();
  }

  public refresh() {
    this.getLists();
  }

  private getLists() {
    this.refreshing.next(true);
    let lists = prebuiltLists;
    try {
      localStorage.getItem('savedLists');
      const localLists = JSON.parse(localStorage.getItem('savedLists') || '[]');
      lists = lists.concat(localLists).sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      this.notify.error('Error getting saved lists');
    }
    this.listsSource.next(lists);
    this.refreshing.next(false);
  }

  public deleteList(listId: string) {
    const lists = this.listsSource.value || [];
    const updatedLists = lists.filter((list) => list.id !== listId);
    localStorage.setItem('savedLists', JSON.stringify(updatedLists));
    this.listsSource.next(updatedLists);
  }
}
