import { DWColumn, ColumnType } from '../data-warehouse/dw-column';

export interface SearchFilterOperator {
  key: string;
  displayName: string;
  inputsRequired: number;
  types: ColumnType[];
}

export function getPeopleColumnByName(name: string): DWColumn {
  const col = PeopleColumns.find((col) => col.name === name);
  if (!col) {
    throw new Error(`Column ${name} not found`);
  }

  return col;
}

export const PeopleColumns: DWColumn[] = [
  {
    name: 'jobs_search',
    displayName: 'Company',
    type: ColumnType.companySearch,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'company_change_detected_at',
    displayName: 'Company Change Detected',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'connections',
    displayName: 'Number of Connections',
    type: ColumnType.number,
    group: 'personal',
  },
  {
    name: 'country',
    displayName: 'Country',
    type: ColumnType.string,
    group: 'personal',
    isPopular: true,
  },
  {
    name: 'location_details.country_code',
    displayName: 'Country Code',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'location_details.region_abbreviation',
    displayName: 'Region/State Abbreviation',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'location_details.msa',
    displayName: 'MSA',
    type: ColumnType.string,
    group: 'personal',
    isPopular: true,
  },
  { name: 'id', displayName: 'Person ID', type: ColumnType.string, group: 'personal' },
  {
    name: 'info_change_detected_at',
    displayName: 'Any Info Changed',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'linkedin',
    displayName: 'LinkedIn Profile',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'personal',
    isPopular: true,
  },
  { name: 'name', displayName: 'Full Name', type: ColumnType.string, group: 'personal' },
  { name: 'first_name', displayName: 'First Name', type: ColumnType.string, group: 'personal' },
  { name: 'last_name', displayName: 'Last Name', type: ColumnType.string, group: 'personal' },
  {
    name: 'cell_phone_number',
    displayName: 'Cell Phone',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'jobs.company.id',
    displayName: 'LDT Company ID',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.address',
    displayName: 'Company Address',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.country',
    displayName: 'Company Country',
    type: ColumnType.string,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.company.employee_count',
    displayName: 'Company Employee Count',
    type: ColumnType.number,
    group: 'jobs',
  },
  {
    name: 'jobs.company.industry',
    displayName: 'Company Industry',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.linkedin',
    displayName: 'Company LinkedIn ID',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.location',
    displayName: 'Company Location',
    type: ColumnType.string,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.company.naics_codes',
    displayName: 'Company NAICS Codes',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.name',
    displayName: 'Company Name',
    type: ColumnType.string,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.company.sic_codes',
    displayName: 'Company SIC Codes',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.ticker',
    displayName: 'Company Ticker',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.type',
    displayName: 'Company Type',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.company.domain',
    displayName: 'Company Domain',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.direct_dial_number',
    displayName: 'Direct Dial Number',
    type: ColumnType.string,
    group: 'jobs',
  },
  { name: 'jobs.email', displayName: 'Email', type: ColumnType.string, group: 'jobs' },
  {
    name: 'jobs.email_status',
    displayName: 'Email Status',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.started_at',
    displayName: 'Job Started At',
    type: ColumnType.date,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.ended_at',
    displayName: 'Job Ended At',
    type: ColumnType.date,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.duration',
    displayName: 'Job Duration (mos)',
    type: ColumnType.number,
    group: 'jobs',
  },
  {
    name: 'jobs.company_tenure',
    displayName: 'Tenure at Company (mos)',
    type: ColumnType.number,
    group: 'jobs',
  },
  {
    name: 'jobs.title',
    displayName: 'Job Title',
    type: ColumnType.string,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.function',
    displayName: 'Job Function',
    type: ColumnType.jobfunction,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.level',
    displayName: 'Job Level',
    type: ColumnType.joblevel,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'jobs',
    isPopular: true,
  },
  {
    name: 'jobs.location_details.country_code',
    displayName: 'Country Code',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.location_details.region_abbreviation',
    displayName: 'Region/State Abbreviation',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.location_details.msa',
    displayName: 'MSA',
    type: ColumnType.string,
    group: 'jobs',
  },
  {
    name: 'jobs.started_at_year_only',
    displayName: 'Job Started At Year Only',
    type: ColumnType.boolean,
    group: 'jobs',
  },
  {
    name: 'jobs.ended_at_year_only',
    displayName: 'Job Ended At Year Only',
    type: ColumnType.boolean,
    group: 'jobs',
  },
  {
    name: 'title_change_detected_at',
    displayName: 'Title Change Detected',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'education.degree',
    displayName: 'Education Degree Name',
    group: 'education',
    type: ColumnType.string,
  },
  {
    name: 'education.ended_at',
    displayName: 'Education End Date',
    type: ColumnType.date,
    group: 'education',
  },
  {
    name: 'education.field',
    displayName: 'Education Field',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.school',
    displayName: 'School Name',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.started_at',
    displayName: 'Education Start Date',
    type: ColumnType.date,
    group: 'education',
  },
];

export const SupportedOperators: { [key: string]: SearchFilterOperator } = {
  fuzzyMatch: {
    key: 'fuzzyMatch',
    displayName: 'Matches (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  fuzzyNotMatch: {
    key: 'fuzzyNotMatch',
    displayName: 'Does not match (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  equals: {
    key: 'equals',
    displayName: 'Equals',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  notEqual: {
    key: 'notEqual',
    displayName: 'Does not equal',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  blank: {
    key: 'blank',
    displayName: 'Blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  notBlank: {
    key: 'notBlank',
    displayName: 'Not blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  greaterThanOrEqual: {
    key: 'greaterThanOrEqual',
    displayName: 'Greater than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThanOrEqual: {
    key: 'lessThanOrEqual',
    displayName: 'Less than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThan: {
    key: 'lessThan',
    displayName: 'Less than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  greaterThan: {
    key: 'greaterThan',
    displayName: 'Greater than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  inRange: {
    key: 'inRange',
    displayName: 'In range',
    inputsRequired: 2,
    types: [ColumnType.date, ColumnType.number],
  },
};
