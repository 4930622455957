import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invitations-create-dialog',
  templateUrl: './invitations-create-dialog.component.html',
  styleUrls: ['./invitations-create-dialog.component.scss'],
})
export class InvitationsCreateDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InvitationsCreateDialogComponent>
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      emails: ['', [Validators.required]], // using custom validator for email list
      role: ['', [Validators.required]],
    });
  }

  // close the dialog and send form data back as an array of emails
  save() {
    if (this.form.valid) {
      const formValue = { ...this.form.value };
      formValue.emails = this.parseEmails(formValue.emails); // transform emails into an array
      this.dialogRef.close(formValue); // passes emails as an array to parent
    }
  }

  close() {
    this.dialogRef.close();
  }

  parseEmails(emails: string): string[] {
    return emails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email.length > 0); // remove empty strings from split
  }
}
