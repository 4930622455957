import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ITextFilterParams,
  SideBarDef,
} from 'ag-grid-community';
import {
  Company,
  Job,
  Person,
  PersonSearchFilter,
  SearchFilter,
  SearchPersonsData200Response,
  SearchService,
  SearchPersonsDataRequest,
  FilterGroup,
  FilterGroupFiltersInner,
} from 'ldt-people-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'people-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [DatePipe],
})
export class PeopleDetailsComponent implements OnInit {
  orgId: string;

  refreshing: boolean = true;
  countUpdating: boolean = true;
  downloading: boolean = false;
  viewCount = 0;
  paginationToken: string | undefined;

  private gridApi: GridApi;
  gridColumnApi: ColumnApi;
  rowData: Person[];
  tooltipShowDelay = 200;
  rowModelType: string = 'serverSide';
  serverSideDatasource: IServerSideDatasource = this.dwDatasource();
  cacheBlockSize = 100;
  rowSelection = 'single';

  constructor(
    private datePipe: DatePipe,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private peopleSearch: SearchService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.authService.getSelectedOrgIdValue;
      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;
  }

  /// AG -GRID --------------
  private betterDateFilterParams: ITextFilterParams = {
    filterOptions: [
      'equals',
      {
        displayKey: 'greaterThanOrEqualsCustom',
        displayName: 'Greater than or equals',
        predicate: ([filterValue], cellValue) => true,
      },
      {
        displayKey: 'lessThanOrEqualsCustom',
        displayName: 'Less than or equals',
        predicate: ([filterValue], cellValue) => true,
      },
      'notEqual',
      'inRange',
      'blank',
      'notBlank',
    ],
    defaultOption: 'greaterThanOrEqualsCustom',
    maxNumConditions: 1,
  };
  private textFilterParams: ITextFilterParams = {
    filterOptions: ['startsWith', 'equals', 'notEqual', 'blank', 'notBlank'],
    defaultOption: 'startsWith',
    maxNumConditions: 1,
  };
  private numberFilterParams: ITextFilterParams = {
    filterOptions: [
      'equals',
      'notEqual',
      'lessThan',
      'lessThanOrEqual',
      'greaterThan',
      'greaterThanOrEqual',
      'inRange',
      'blank',
      'notBlank',
    ],
    defaultOption: 'greaterThanOrEqual',
    maxNumConditions: 1,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: this.textFilterParams,
    resizable: true,
    flex: 1,
    minWidth: 100,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  sideBar: SideBarDef = {
    defaultToolPanel: 'columns',
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
  };
  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      headerName: 'ID',
      headerTooltip: 'Unique ID of this contact in the Live Data system',
    },
    {
      headerName: 'Dates',
      children: [
        {
          field: 'company_change_detected_at',
          headerName: 'Job Change',
          filter: 'agDateColumnFilter',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          field: 'title_change_detected_at',
          headerName: 'Title Change',
          filter: 'agDateColumnFilter',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          field: 'info_change_detected_at',
          headerName: 'Other Change',
          filter: 'agDateColumnFilter',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          field: 'created_at',
          headerName: 'Created',
          filter: 'agDateColumnFilter',
          valueFormatter: (params: any) => {
            return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
      ],
    },
    {
      headerName: 'Person Info',
      children: [
        { field: 'name' },
        {
          field: 'connections',
          filter: 'agNumberColumnFilter',
          filterParams: this.numberFilterParams,
        },
        { field: 'country' },
        { field: 'location' },
        {
          field: 'linkedin',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/in/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
          headerTooltip:
            'The LinkedIn ID of this person. Always uses the customer-provide ID, if provided. If not provided, ID was discovered by Live Data.',
        },
      ],
    },
    {
      headerName: 'Current Job',
      children: [
        { field: 'position.company.name', headerName: 'Company Name' },
        {
          field: 'position.title',
          headerName: 'Title',
          headerTooltip: 'The verified title this person currently holds',
        },
        {
          field: 'position.level',
          headerName: 'Job Level',
          filterParams: { values: Object.values(Job.LevelEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip: 'The inferred level of the current job, based on the title.',
        },
        {
          field: 'position.function',
          headerName: 'Job Function',
          filterParams: { values: Object.values(Job.FunctionEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip: 'The inferred domain of the current job, based on the title.',
        },
        {
          field: 'position.started_at',
          headerName: 'Start Date',
          headerTooltip: 'The date the person started at their current job',
          filter: 'agDateColumnFilter',
          valueFormatter: (params: any) => {
            let tString = 'yyyy-MM';
            if (params?.data?.position?.metadata?.started_at_year_only) tString = 'yyyy';
            return (
              this.datePipe.transform(params?.data?.position?.started_at, tString, 'UTC') || ''
            );
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          field: 'position.email',
          headerName: 'Email',
          headerTooltip: 'The current email address of the contact, if known.',
        },
        {
          field: 'position.email_status',
          headerName: 'Email Status',
          filterParams: { values: Object.values(Job.EmailStatusEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip:
            'The status of the email address. "valid" denotes a valid email while "catchall" denotes a likely email address that could not be positively confirmed.',
        },
        { field: 'position.location', headerName: 'Job Location' },
      ],
    },
    {
      headerName: 'Company Firmo',
      children: [
        { field: 'position.company.address', headerName: 'Address' },
        { field: 'position.company.country', headerName: 'Country' },
        {
          field: 'position.company.employee_count',
          headerName: 'Employees',
          filterParams: this.numberFilterParams,
          filter: 'agNumberColumnFilter',
        },
        { field: 'position.company.industry', headerName: 'Industry' },
        {
          field: 'position.company.linkedin',
          headerName: 'LinkedIn',
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/company/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
        },
        { field: 'position.company.location', headerName: 'Location' },
        { field: 'position.company.sic_codes', headerName: 'SIC' },
        { field: 'position.company.ticker', headerName: 'Ticker' },
        {
          field: 'position.company.type',
          headerName: 'Type',
          filterParams: { values: Object.values(Company.TypeEnum) },
          filter: 'agSetColumnFilter',
        },
        { field: 'position.company.domain', headerName: 'Domain' },
      ],
    },
    {
      headerName: 'Previous Job',
      children: [
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.company.name,
          headerName: 'Company Name',
          field: 'jobs.company.name',
          sortable: false,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.title,
          headerName: 'Title',
          field: 'jobs.title',
          sortable: false,
          headerTooltip: 'The verified title of this person at this job',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.level,
          headerName: 'Job Level',
          field: 'jobs.level',
          sortable: false,
          filterParams: { values: Object.values(Job.LevelEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip: 'The inferred level of the job, based on the title.',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.function,
          headerName: 'Job Function',
          field: 'jobs.function',
          sortable: false,
          filterParams: { values: Object.values(Job.FunctionEnum) },
          filter: 'agSetColumnFilter',
          headerTooltip: 'The inferred domain of the job, based on the title.',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.started_at,
          headerName: 'Start Date',
          filter: 'agDateColumnFilter',
          field: 'jobs.started_at',
          sortable: false,
          headerTooltip: 'The date the person started at this job',
          valueFormatter: (params: any) => {
            let tString = 'yyyy-MM';
            if (params?.data?.jobs[1]?.metadata?.started_at_year_only) tString = 'yyyy';
            return this.datePipe.transform(params?.data?.jobs[1]?.started_at, tString, 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.ended_at,
          headerName: 'End Date',
          filter: 'agDateColumnFilter',
          field: 'jobs.ended_at',
          sortable: false,
          headerTooltip: 'The date the person ended at this job',
          valueFormatter: (params: any) => {
            let tString = 'yyyy-MM';
            if (params?.data?.jobs[1]?.metadata?.ended_at_year_only) tString = 'yyyy';
            return this.datePipe.transform(params?.data?.jobs[1]?.ended_at, tString, 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.location,
          headerName: 'Job Location',
          field: 'jobs.company.location',
          sortable: false,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[1]?.company.linkedin,
          headerName: 'Company LinkedIn',
          field: 'jobs.company.linkedin',
          sortable: false,
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/company/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
        },
      ],
    },
    {
      headerName: 'Previous Job - 2',
      children: [
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.company.name,
          headerName: 'Company Name',
          filter: false,
          sortable: false,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.title,
          headerName: 'Title',
          filter: false,
          sortable: false,
          headerTooltip: 'The verified title of this person at this job',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.level,
          headerName: 'Job Level',
          filter: false,
          sortable: false,
          headerTooltip: 'The inferred level of the job, based on the title.',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.function,
          headerName: 'Job Function',
          filter: false,
          sortable: false,
          headerTooltip: 'The inferred domain of the job, based on the title.',
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.started_at,
          headerName: 'Start Date',
          filter: false,
          sortable: false,
          headerTooltip: 'The date the person started at this job',
          valueFormatter: (params: any) => {
            let tString = 'yyyy-MM';
            if (params?.data?.jobs[2]?.metadata?.started_at_year_only) tString = 'yyyy';
            return this.datePipe.transform(params?.data?.jobs[2]?.started_at, tString, 'UTC') || '';
          },
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.ended_at,
          headerName: 'End Date',
          filter: false,
          sortable: false,
          headerTooltip: 'The date the person ended at this job',
          valueFormatter: (params: any) => {
            let tString = 'yyyy-MM';
            if (params?.data?.jobs[2]?.metadata?.ended_at_year_only) tString = 'yyyy';
            return this.datePipe.transform(params?.data?.jobs[2]?.ended_at, tString, 'UTC') || '';
          },
          filterParams: this.betterDateFilterParams,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.location,
          headerName: 'Job Location',
          filter: false,
          sortable: false,
        },
        {
          valueGetter: (params: any) => params?.data?.jobs[2]?.company.linkedin,
          headerName: 'Company LinkedIn',
          filter: false,
          sortable: false,
          cellRenderer: (params: { value: any }) => {
            if (!params.value) {
              return '';
            } else {
              return (
                '<a href="https://www.linkedin.com/company/' +
                params.value +
                '" target=_blank>' +
                params.value +
                '</a>'
              );
            }
          },
        },
      ],
    },
  ];
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const savedColumnState = localStorage.getItem('ag-grid-columns-people-details');

    // Get the saved column state from localstorage if a user has hidden certain columns
    // But also reset the sort order to last job change desc
    if (savedColumnState) {
      this.gridApi.closeToolPanel();

      if (
        this.gridColumnApi.getColumnState().length != JSON.parse(savedColumnState || '{}').length
      ) {
        this.notify.info(
          'Grid columns have changed, so your previous view preferences have been reset'
        );
        this.saveColumnState();
        this.gridApi.openToolPanel('columns');
      } else {
        this.gridColumnApi.applyColumnState({ state: JSON.parse(savedColumnState || '{}') });
      }
    }
    this.gridColumnApi.applyColumnState({
      state: [{ colId: 'company_change_detected_at', sort: 'desc' }],
      defaultState: { sort: null },
    });

    // Get any filtering specified in the querystring and apply it
    if (this.route.snapshot.queryParams.filter) {
      this.gridApi.setFilterModel(
        JSON.parse(decompressFromEncodedURIComponent(this.route.snapshot.queryParams.filter))
      );
    }
  }

  saveColumnState() {
    if (this.gridColumnApi) {
      const currentState = this.gridColumnApi.getColumnState();
      localStorage.setItem('ag-grid-columns-people-details', JSON.stringify(currentState));
    }
  }

  agGridFiltersToSearchFilters(filterModel: any): FilterGroup[] {
    let searchFilters: FilterGroupFiltersInner[] = [];
    let jobsFilters: FilterGroupFiltersInner = {
      filters: [],
      operator: 'and',
    };

    Object.keys(filterModel).forEach((fieldName: any) => {
      var match_type;
      var search_type;

      let filter: PersonSearchFilter = { field: fieldName };

      switch (filterModel[fieldName].filterType) {
        case 'set':
          if (filterModel[fieldName].values.length == 0) {
            return;
          }

          filter.match_type = SearchFilter.MatchTypeEnum.Exact;
          filter.type = SearchFilter.TypeEnum.Must;
          filter.string_values = filterModel[fieldName].values;
          break;

        case 'text':
          match_type = SearchFilter.MatchTypeEnum.Exact;
          search_type = SearchFilter.TypeEnum.Must;

          switch (filterModel[fieldName].type) {
            case 'startsWith':
              match_type = SearchFilter.MatchTypeEnum.Fuzzy;
              search_type = SearchFilter.TypeEnum.Must;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'equals':
              match_type = SearchFilter.MatchTypeEnum.Exact;
              search_type = SearchFilter.TypeEnum.Must;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'notEqual':
              match_type = SearchFilter.MatchTypeEnum.Exact;
              search_type = SearchFilter.TypeEnum.MustNot;
              filter.string_values = filterModel[fieldName].filter.split(',');
              break;
            case 'blank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.Must;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          break;
        case 'number':
          var search_min;
          var search_max;
          switch (filterModel[fieldName].type) {
            case 'equals':
              search_type = SearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filter;
              break;
            case 'notEqual':
              search_type = SearchFilter.TypeEnum.MustNot;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filter;
              break;
            case 'lessThan':
              search_type = SearchFilter.TypeEnum.Must;
              search_max = filterModel[fieldName].filter - 1;
              break;
            case 'lessThanOrEqual':
              search_type = SearchFilter.TypeEnum.Must;
              search_max = filterModel[fieldName].filter;
              break;
            case 'greaterThan':
              search_type = SearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter - 1;
              break;
            case 'greaterThanOrEqual':
              search_type = SearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              break;
            case 'inRange':
              search_type = SearchFilter.TypeEnum.Must;
              search_min = filterModel[fieldName].filter;
              search_max = filterModel[fieldName].filterTo;
              break;
            case 'blank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.Must;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          if (search_min) filter.number_min = search_min;
          if (search_max) filter.number_max = search_max;
          if (match_type) filter.match_type = match_type;

          break;
        case 'date':
          var search_from;
          var search_to;
          const parsed_from = filterModel[fieldName].dateFrom
            ? moment(new Date(filterModel[fieldName].dateFrom)).format('YYYY-MM-DD')
            : undefined;
          const parsed_to = filterModel[fieldName].dateTo
            ? moment(new Date(filterModel[fieldName].dateTo)).format('YYYY-MM-DD')
            : undefined;

          switch (filterModel[fieldName].type) {
            case 'equals':
              search_type = SearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              search_to = parsed_from;
              break;
            case 'notEqual':
              search_type = SearchFilter.TypeEnum.MustNot;
              search_from = parsed_from;
              search_to = parsed_from;
              break;
            case 'lessThanOrEqualsCustom':
              search_type = SearchFilter.TypeEnum.Must;
              search_to = parsed_from;
              break;
            case 'greaterThanOrEqualsCustom':
              search_type = SearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              break;
            case 'inRange':
              search_type = SearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              search_to = parsed_to;
              break;
            case 'blank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.MustNot;
              break;
            case 'notBlank':
              match_type = SearchFilter.MatchTypeEnum.Exists;
              search_type = SearchFilter.TypeEnum.Must;
              break;
            default:
              search_type = SearchFilter.TypeEnum.Must;
              search_from = parsed_from;
              break;
          }

          filter.match_type = match_type;
          filter.type = search_type;

          if (search_from) filter.date_from = search_from;
          if (search_to) filter.date_to = search_to;
          if (match_type) filter.match_type = match_type;

          break;
      }

      if (fieldName.startsWith('jobs.')) {
        jobsFilters.filters!.push(filter);
      } else {
        searchFilters.push(filter);
      }
    });

    if (jobsFilters.filters!.length > 0) {
      searchFilters.push(jobsFilters);
    }

    return searchFilters;
  }

  dwDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.hideOverlay();
        const size = (params.request.endRow || 0) - (params.request.startRow || 0);

        // If the grid is back at the top, reset the pagination token
        if (params.request.startRow === 0) {
          delete this.paginationToken;
          this.countUpdating = true;
        }

        const searchFilters = this.agGridFiltersToSearchFilters(params.request.filterModel);
        if (Object.keys(params.request.filterModel).length > 0) {
          const encodedFilter = compressToEncodedURIComponent(
            JSON.stringify(params.request.filterModel)
          );
          let url = '/' + this.orgId + '/people/details?filter=' + encodedFilter;
          this.router.navigateByUrl(url);
        }

        var sortBy = undefined;
        if (params.request.sortModel.length > 0) {
          sortBy = params.request.sortModel[0].colId;
          if (params.request.sortModel[0].sort == 'desc') {
            sortBy = '-' + sortBy;
          }
        }

        let searchRequest: SearchPersonsDataRequest = {
          filters: searchFilters,
          size: size,
          pagination_token: this.paginationToken,
          sort_by: sortBy,
        };
        this.peopleSearch.searchPersonsData(this.orgId, searchRequest).subscribe({
          next: (res: SearchPersonsData200Response) => {
            params.success({
              rowData: res?.people || [],
            });
            this.paginationToken = res.pagination_token || undefined;
            this.viewCount = res.count || 0;
            this.refreshing = false;
            this.countUpdating = false;
          },
          error: () => {
            params.fail();
            this.notify.error('Oops. There was an error getting the data.');
            this.viewCount = 0;
            this.refreshing = false;
            this.countUpdating = false;
          },
        });
      },
    };
  }

  refreshData() {
    if (this.gridApi) {
      this.refreshing = true;
      this.gridApi.refreshServerSide({ purge: true });
    }
  }

  downloadData() {
    if (this.viewCount > 10000) {
      this.notify.error(
        'You can only download up to 10,000 records at a time. Please narrow your search.'
      );
      return;
    }

    this.downloading = true;
    const searchFilters = this.agGridFiltersToSearchFilters(this.gridApi.getFilterModel());

    let searchRequest: SearchPersonsDataRequest = {
      filters: searchFilters,
    };

    this.peopleSearch.downloadPersonsData(this.orgId, searchRequest).subscribe({
      next: (response: any) => {
        var data = new Blob([response], { type: 'text/csv' });
        FileSaver.saveAs(data, 'livedata-download-' + moment().format('YYYYMMDD-HHmmss') + '.csv');
        this.downloading = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.downloading = false;
      },
    });
  }
}
