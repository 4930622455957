import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MxService } from '../shared/mx-service/mx.service';
import { NotificationService } from '../shared/notification-service/notification.service';
import { AuthService } from './service/auth.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly EXPIRY_BUFFER_MS = 5 * 1000;

  constructor(
    private readonly auth: AuthService,
    private route: Router,
    private notify: NotificationService,
    private mx: MxService
  ) {}

  private _refreshSubject: Subject<any> = new Subject<any>();

  private _shouldRefreshToken(): boolean {
    const expiresAt = this.auth.getTokenExpirationTime();
    if (!expiresAt) return true;

    return Date.now() + this.EXPIRY_BUFFER_MS >= expiresAt;
  }

  private _ifTokenExpired() {
    this._refreshSubject.subscribe({
      complete: () => {
        this._refreshSubject = new Subject<any>();
      },
    });
    if (this._refreshSubject.observers.length <= 1) {
      // TODO: the above statement was === 1, but for some reason the observers were 0, only recently

      // Hit refresh-token API passing the refresh token stored into the request
      // to get new access token and refresh token pair
      this.auth.refreshSession().subscribe(this._refreshSubject);
    }
    return this._refreshSubject;
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    let expired: boolean =
      error.status &&
      error.status === 401 &&
      error.error &&
      ((error.error.message && error.error.message.includes('expired')) ||
        (typeof error.error === 'string' && error.error.includes('expired')));
    return expired;
  }

  private handleError(req: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {
    // If grantType == password, the user is trying to login with their password. Pass the error along
    // Also if a recaptcha is in the request, the 401 should be returned since it's likely a recaptcha failure
    if (
      err.status === 401 &&
      !(req.body?.grantType && req.body?.grantType === 'password') &&
      !req.body?.recaptchaToken
    ) {
      this.notify.error('Your session has expired. Please login again.');
      this.auth.logOut();
      this.route.navigateByUrl(`/login`);
      return of(true); // Do not log/throw 401s
    }
    if (err.status === 503) {
      if (!this.mx.getMxModeValue) {
        this.mx.setMxMode(true);
        return of(true); // Do not log/throw 503s
      }
    }

    // Group errors together based on their request and response
    Sentry.withScope(function (scope) {
      // Skip 404 errors from Clean jobs
      if (
        err &&
        err.status &&
        err.status === 404 &&
        req.url.startsWith('https://gotlivedata.io/api/clean/v2/cleanJobItem')
      ) {
        return;
      }
      // Strip IDs out of the request URL for error grouping
      let logUrl = req.url
        .split('/')
        .filter((x) => !x.includes('_'))
        .join('/');
      scope.setFingerprint([req.method, logUrl, String(err.status)]);
      scope.setTransactionName(req.method + ' ' + logUrl + ' ' + err.status);
      Sentry.captureException(err);
    });
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/logout') || req.url.endsWith('/session')) {
      return next.handle(req).pipe(catchError((err) => this.handleError(req, err)));
    }

    // Check if token needs refresh before making request
    if (this._shouldRefreshToken()) {
      return this._ifTokenExpired().pipe(
        switchMap(() => {
          return next.handle(this.updateHeader(req));
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return this.handleError(req, error);
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (this._checkTokenExpiryErr(error)) {
            return this._ifTokenExpired().pipe(
              switchMap(() => {
                return next.handle(this.updateHeader(req));
              })
            );
          } else {
            return this.handleError(req, error);
          }
        }
        return caught;
      })
    );
  }

  updateHeader(req: any) {
    const authToken = this.auth.getAccessTokenValue;
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });
    return req;
  }
}
