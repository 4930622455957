import { Component } from '@angular/core';
import { Product, ProductsService, UpdatePriceRequest } from 'ldt-billing-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ColDef } from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { OrgSettingsEditorModalComponent } from '../../shared/org-settings-editor/org-settings-editor-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class AdminBillingProductsComponent {
  refreshing: boolean = false;
  rowData: Product[] = [];

  constructor(
    private productsService: ProductsService,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getProducts();
  }

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  // provide Detail Cell Renderer Params
  detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      columnDefs: [
        { field: 'id' },
        { field: 'active' },
        { field: 'interval' },
        { field: 'unit_amount' },
        {
          field: 'org_settings',
          cellRenderer: BtnCellRenderer,
          cellRendererParams: {
            onClick: this.updatePriceSettings.bind(this),
            label: 'Update Settings',
            display: 'compact',
          },
        },
      ],
    },
    getDetailRowData: function (params: any) {
      params.successCallback(params.data.stripeProduct.prices);
    },
  };

  public colDefsTypes: ColDef[] = [
    { field: 'stripeProduct.id', maxWidth: 200 },
    { field: 'stripeProduct.name', floatingFilter: true, cellRenderer: 'agGroupCellRenderer' },
    {
      field: 'stripeProduct.prices',
      valueGetter: (params) =>
        params.data.stripeProduct.prices.map((p: any) => p.term + ' - ' + p.unitAmount).join(', '),
    },
  ];

  updatePriceSettings(params: any) {
    const priceId = params.rowData.id;
    if (!priceId) {
      this.notify.error('Error updating price settings');
      return;
    }

    const dialogRef = this.dialog.open(OrgSettingsEditorModalComponent, {
      width: '800px',
      data: {
        usePartialUpdate: true,
        initialSettings: params.rowData.org_settings || {},
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let req: UpdatePriceRequest = {
          org_settings: result,
        };
        this.productsService.updatePrice(priceId, req).subscribe({
          next: () => {
            this.notify.success('Price settings updated');
            this.getProducts();
          },
          error: () => {
            this.notify.error('Error updating price settings');
          },
        });
      }
    });
  }

  getProducts(): void {
    this.refreshing = true;
    this.productsService.getProducts().subscribe({
      next: (data) => {
        this.rowData = data.products;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Error fetching products');
        this.refreshing = false;
      },
    });
  }
}
