<div
  class="tw-flex tw-row tw-justify-between tw-items-center tw-flex-wrap tw-mb-4 people-container">
  <div>
    <h1 class="content-title tw-items-center tw-mb-0 tw-mt-0">Live Data People</h1>
  </div>
</div>

<div class="content tw-flex-col">
  <nav mat-tab-nav-bar>
    <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details" class="tw-text-base">
      Search
    </a>
    <a
      *ngIf="isAdmin | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./filters"
      class="tw-text-base">
      Explore
    </a>
    <a
      *ngIf="isAdmin | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./find"
      class="tw-text-base">
      Find
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
