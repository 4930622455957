<h2 mat-dialog-title class="tw-font-semibold tw-mb-4">Create Service Account</h2>

<mat-dialog-content [formGroup]="form" class="tw-gap-4">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput placeholder="Name" formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Role</mat-label>
    <mat-select formControlName="role">
      <mat-option value="viewer">viewer</mat-option>
      <mat-option value="editor">editor</mat-option>
      <mat-option value="admin">admin</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="tw-px-6 tw-py-5">
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button
    mat-raised-button
    [disabled]="!form.valid"
    color="primary"
    (click)="save()"
    class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
    Create
  </button>
</mat-dialog-actions>
