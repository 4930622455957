const getGroupIconString = (series: Highcharts.Series): string => {
  const showGroup = series.userOptions.custom?.showGroupIcon || false;
  const groupIcon = showGroup
    ? ' <img src="../../assets/img/office-building.png" alt="office building conglomerate" class="tw-w-4 tw-align-middle tw-mb-1">'
    : '';

  return groupIcon;
};

// Legend formatter: truncates long company names and adds a building icon if conglomerate
const labelFormatter = function (series: Highcharts.Series): string {
  const maxLength = 20;
  const groupIcon = getGroupIconString(series);

  const text =
    series.name.length > maxLength ? `${series.name.substring(0, maxLength)}...` : series.name;

  return `${text}${groupIcon}`;
};

// Tooltip formatter for charts where format is the same (tenureCohortChart and churnRateByMonthChart)
function tooltipFormatter(this: any): string {
  const series = this.series;
  const groupIcon = getGroupIconString(series);
  const pointValue = (this.y || 0).toFixed(2);
  return `${series.name} ${groupIcon}: <b>${pointValue}%</b>`;
}

export const timeseriesChartXaxis: Highcharts.XAxisOptions = {
  type: 'datetime',
  dateTimeLabelFormats: {
    day: '%e %b %Y', // format for the dates on the xAxis
  },
  labels: {
    formatter: undefined,
  },
  title: {
    style: {
      fontFamily: '"Montserrat", sans-serif',
    },
  },
};

export const columnChartXAaxis: Highcharts.XAxisOptions = {
  type: 'category',
  categories: undefined,
  labels: {
    useHTML: true,
    formatter: function () {
      const hasGroup = this.value.toString().endsWith('(groupicon)');
      const val = this.value.toString().replace('(groupicon)', '');
      return (
        '' +
        val +
        (hasGroup
          ? ' <img src="../../assets/img/office-building.png" alt="office building conglomerate" class="tw-w-4 tw-align-middle tw-mb-1">'
          : '')
      );
    },
  },
};

// Chart Headcount
export const arrDepChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    spacingTop: 15, // a bit of top padding
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Headcount',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: timeseriesChartXaxis,
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Number of Employees',
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          return this.value.toLocaleString();
        },
      },
    },
  ],
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    useHTML: true,
    labelFormatter: function () {
      return labelFormatter(this as Highcharts.Series);
    },
  },
  // change content based on switch value, show building icon if group
  tooltip: {
    useHTML: true,
    formatter: function () {
      const series = this.series;
      const groupIcon = getGroupIconString(series);
      const pointValue = this.y || 0;
      const isPercentage = (series.yAxis as any).axisTitle?.textStr === 'Pct Change';

      const formattedValue = isPercentage
        ? pointValue.toFixed(2) + '%'
        : pointValue.toLocaleString();

      return `${series.name} ${groupIcon}: <b>${formattedValue}</b>`;
    },
  },
};

// Chart Tenure over time
export const tenureByMonthChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    spacingTop: 15,
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Tenure by Month',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Average Tenure (months)',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    sourceWidth: 1200,
  },
  legend: {
    useHTML: true,
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    labelFormatter: function () {
      return labelFormatter(this as Highcharts.Series);
    },
  },
  tooltip: {
    useHTML: true,
    formatter: function () {
      const series = this.series;
      const groupIcon = getGroupIconString(series);
      const pointValue = (this.y || 0).toFixed(2);
      const sampleSize = (this.point as any).sampleSize || 'N/A'; // point is not in Highcharts types
      const pointFormat = `${series.name} ${groupIcon}<br/><b>${pointValue}</b> (N=${sampleSize})`;

      return pointFormat;
    },
  },
};

// Chart Sankey
export const sankeyChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    spacingTop: 15,
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        const logoWidth = 121;
        const logoHeight = logoWidth / 5.05;
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Add the logo image and store the reference in customLogo
        chart.customLogo = chart.renderer
          .image(logoUrl, x, chart.chartHeight - logoHeight - 10, logoWidth, logoHeight)
          .add();
      },
      redraw: function () {
        const chart = this as any;
        const logo = chart.customLogo;

        if (!logo) return; // in case the logo is not initialized

        // Ensure the logo stays centered during a redraw (resize)
        let logoWidth = 121;
        let logoHeight = logoWidth / 5.05;

        if (chart.chartWidth < 480) {
          logoWidth = 90; // for smaller containers
          logoHeight = logoWidth / 5.05;
        }

        // Recalculate the x position to keep it centered
        const x = chart.chartWidth / 2 - logoWidth / 2;

        // Update the logo position and size
        logo.attr({
          x: x,
          y: chart.chartHeight - logoHeight - 10,
          width: logoWidth,
          height: logoHeight,
        });
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'All Employee Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      nodePadding: 5,
      dataLabels: {
        enabled: true,
        style: {},
      },
    },
  },
  series: [
    {
      type: 'sankey',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};

// Chart Poaching
export const wheelChartNoSeries: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    spacingTop: 15,
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Inter-company Movement',
    style: {
      fontWeight: '600',
    },
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
  plotOptions: {
    sankey: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [
    {
      type: 'dependencywheel',
      keys: ['from', 'to', 'weight'],
      data: [],
    },
  ],
  exporting: {
    sourceWidth: 1200,
  },
};

// Current Employee Tenure Breakdown
export const tenureCohortChart: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    spacingTop: 15,
    parallelCoordinates: true,
    parallelAxes: {
      labels: {
        style: {
          color: '#999999',
        },
      },
      lineWidth: 2,
      showFirstLabel: false,
      showLastLabel: false,
    },
    polar: true,
    backgroundColor: '#ffffff',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Current Employee Tenure Breakdown',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    categories: ['0-2 years', '3-4 years', '5-7 years', '8-10 years', '11+ years'],
    labels: {
      distance: 30,
      style: {
        fontWeight: 'bold',
      },
    },
    gridLineWidth: 0,
    tickmarkPlacement: 'on',
    lineWidth: 0,
  },
  yAxis: [
    {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      allowDecimals: false,
      title: {
        text: undefined,
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          const pointValue = this.value as number;
          return pointValue.toFixed(0) + '%';
        },
      },
    },
  ],
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
    },
  },
  legend: {
    useHTML: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    y: 20,
    width: '25%',
    labelFormatter: function () {
      return labelFormatter(this as Highcharts.Series);
    },
  },
  tooltip: {
    useHTML: true,
    formatter: tooltipFormatter,
  },
  pane: {
    size: '80%',
  },
};

// Chart Tenure over time
export const churnRateByMonthChart: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    spacingTop: 15,
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Churn Rate by Month',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: '% of employees that departed',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    sourceWidth: 1200,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    useHTML: true,
    labelFormatter: function () {
      return labelFormatter(this as Highcharts.Series);
    },
  },
  tooltip: {
    useHTML: true,
    formatter: tooltipFormatter,
  },
};

// Chart Tenure over time
export const genericBarChartOptions: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    backgroundColor: '#ffffff',
    type: 'line',
    events: {
      load: function () {
        const chart = this as any;
        const logoUrl = 'assets/img/imagotype2.svg';
        chart.customLogo = chart.renderer
          .image(logoUrl, chart.chartWidth - 131, chart.chartHeight - 34, 121, 24)
          .add();
      },
      redraw: function () {
        const chart = this as any; // Use 'any' type to add custom properties
        const logo = chart.customLogo;
        let logoWidth = 121;
        let logoHeight = 24;
        if (chart.chartWidth < 480) {
          logoWidth = 90; // Smaller width for smaller containers
          logoHeight = logoWidth / 5.04; // Maintain aspect ratio
        }
        if (logo) {
          logo.attr({
            x: chart.chartWidth - (logoWidth + 10),
            y: chart.chartHeight - (logoHeight + 10),
            width: logoWidth,
            height: logoHeight,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  xAxis: {
    title: {
      style: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      stackLabels: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
  },
  exporting: {
    enabled: true,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    itemStyle: {
      fontFamily: '"Montserrat", sans-serif',
    },
    labelFormatter: function () {
      // truncate the legend text and add an ellipsis if necessary
      const maxLength = 20;
      const text = this.name;
      return text.length > maxLength + 2 ? text.substring(0, maxLength) + '...' : text;
    },
  },
};
