<div class="actionContainer">
  <button
    mat-raised-button
    [disabled]="!canEdit || !rowsSelected"
    color="warn"
    (click)="deleteSelectedContacts()">
    Delete Selected Contacts
  </button>
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshData()">
    Refresh
  </button>
  <button
    mat-raised-button
    [class.spinner]="downloading"
    [disabled]="downloading"
    [matMenuTriggerFor]="downloadmenu">
    Download Contacts
    <mat-menu #downloadmenu="matMenu">
      <a mat-menu-item (click)="downloadView();">Current View</a>
      <a mat-menu-item (click)="downloadAll();">All Contacts</a>
    </mat-menu>
  </button>
  <button *ngIf="isAdmin | async" mat-raised-button (click)="searchPerson()">
    View Clean History
  </button>
  <button
    *ngIf="(isAdmin | async) || (orgHasReinit && canAdmin)"
    mat-raised-button
    [class.spinner]="reiniting"
    [disabled]="reiniting"
    (click)="reinitLedger()">
    Re-initialize Contacts
  </button>
  <span
    ><a target="_blank" href="https://docs.gotlivedata.com/docs/ledger-v1-data-fields#/"
      >Understanding the data fields in your ledger</a
    ></span
  >
  <span class="ledgerSize"
    ><mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      *ngIf="countUpdating; else contactCount"></mat-progress-spinner
    ><ng-template #contactCount>{{viewCount | number}}</ng-template> Matching Contacts</span
  >
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%; width: 100%"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [tooltipShowDelay]="tooltipShowDelay"
    [columnDefs]="columnDefs"
    [rowModelType]="rowModelType"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [components]="components"
    [rowSelection]="rowSelection"
    [cacheBlockSize]="cacheBlockSize"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [sideBar]="sideBar"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (displayedColumnsChanged)="saveColumnState()">
  </ag-grid-angular>
</div>
