<div class="tw-flex tw-min-h-full">
  <div
    id="login-signup"
    class="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-bg-gray-100 tw-min-w-[50%]">
    <!-- Main Content -->
    <main class="tw-flex-grow tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
      <!-- email/pw error -->
      <div
        class="tw-flex tw-items-center tw-gap-4 tw-p-4 tw-mb-4 tw-text-base tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50"
        role="alert"
        *ngIf="loginInvalid">
        <img class="tw-h-7" src="../../assets/icons/error.svg" alt="error" />
        <span>You entered an invalid email or password</span>
      </div>

      <!-- invite message -->
      <mat-card
        id="invite"
        *ngIf="isInvite && inviteType == 'personal'"
        class="tw-bg-green-400 tw-my-4 lg:tw-my-8 tw-py-5 tw-px-5 lg:tw-px-10">
        <div>
          You were invited to Live Data by
          <span class="tw-font-semibold">
            {{invitation.invitedBy}} ({{invitation.invitedByEmail}}).
          </span>
        </div>
        <div>Create an account to get started.</div>
      </mat-card>

      <!-- sign up -->
      <section
        *ngIf="!isActionLogin && !inviteError"
        class="bg-gray-50 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-8 tw-mx-auto lg:tw-py-0 lg:tw-min-w-[500px]">
        <a routerLink="/" class="tw-mb-6 lg:tw-mb-8">
          <img src="../../assets/img/imagotype2.svg" alt="logo" />
        </a>
        <div
          class="tw-w-full tw-bg-white tw-rounded-md tw-shadow md:tw-mt-0 sm:tw-max-w-md xl:tw-p-0">
          <div class="tw-p-6 tw-space-y-4 md:tw-space-y-6 sm:tw-p-8">
            <h1
              class="tw-text-xl tw-font-bold tw-leading-tight tw-tracking-tight tw-text-gray-800 md:tw-text-2xl">
              Finish creating your account
            </h1>
            <form
              [formGroup]="form"
              (ngSubmit)="onSubmit($event)"
              class="tw-space-y-4 md:tw-space-y-6">
              <div *ngIf="!(invitation && invitation.inviteeName)">
                <label
                  for="name"
                  class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                  Your Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  formControlName="fullNameFormControl"
                  placeholder="First and last name"
                  required
                  class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
              </div>
              <div *ngIf="!invitation" class="tw-relative">
                <div>
                  <label
                    for="email"
                    class="tw-flex tw-items-center tw-gap-1 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    formControlName="emailFormControl"
                    placeholder="name@company.com"
                    required
                    (focus)="showEmailRequirement = true"
                    (blur)="showEmailRequirement = false"
                    class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
                </div>

                <!-- email reqs -->
                <div
                  *ngIf="showEmailRequirement"
                  class="ldt-tooltip ldt-tooltip-email tw-flex tw-flex-col tw-text-sm tw-text-blue-800 tw-rounded-lg tw-bg-blue-50 tw-p-4 tw-pr-10 tw-my-4
                      tw-absolute -tw-top-32 tw-z-10 tw-font-medium tw-shadow-sm tw-shadow-blue-600 tw-border tw-border-solid tw-border-blue-600"
                  role="tooltip">
                  <div class="">
                    <mat-icon class="material-icons-outlined tw-text-[1.2rem] tw-h-5 -tw-mb-1">
                      info_outline
                    </mat-icon>
                    <span>
                      Invites are tied to the email address that received the invitation. Using a
                      different email address will require a new invitation.
                    </span>
                  </div>
                </div>
              </div>

              <!-- pw -->
              <div class="tw-relative">
                <div>
                  <label
                    for="password"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    formControlName="passwordFormControl"
                    placeholder="••••••••"
                    [type]="showPassword ? 'text' : 'password'"
                    required
                    (input)="checkingPassword($event);"
                    (focus)="showPasswordRequirements = true"
                    (blur)="showPasswordRequirements = false"
                    class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
                </div>

                <!-- pw requirements & validation  -->
                <div
                  *ngIf="showPasswordRequirements"
                  class="ldt-tooltip ldt-tooltip-pwd tw-flex tw-flex-col tw-text-sm tw-text-blue-800 tw-rounded-lg tw-bg-blue-50 tw-p-4 tw-pr-10 tw-my-4
                tw-absolute -tw-top-28 tw-z-10 tw-font-medium tw-shadow-sm tw-shadow-blue-600
                tw-border tw-border-solid tw-border-blue-600"
                  role="tooltip">
                  <div class="tw-flex tw-items-center">
                    <mat-icon
                      class="material-icons-outlined tw-inline tw-text-[1.2rem] tw-h-5 tw-mr-1">
                      info_outline
                    </mat-icon>
                    <span class="tw-font-semibold">Password requirement:</span>
                  </div>

                  <ul class="tw-list-disc tw-list-inside tw-mt-1.5 tw-mb-0">
                    <li
                      *ngFor="let v of pValidation;"
                      class="tw-relative"
                      [ngClass]="{'tw-text-red-500 tw-font-semibold': !v.valid}">
                      <span class="tw-pr-1">{{v.text}}</span>
                      <mat-icon
                        *ngIf="!v.valid"
                        class="material-icons tw-absolute -tw-top-1 tw-text-red-500 tw-inline">
                        warning
                      </mat-icon>
                    </li>
                  </ul>
                </div>

                <!-- show/hide pw -->
                <div class="tw-flex tw-items-center tw-justify-between tw-mt-3">
                  <button
                    type="button"
                    (click)="togglePasswordVisibility()"
                    class="tw-text-gray-700 hover:tw-text-primary_purple-700 tw-transition-all tw-text-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary_purple-600 focus:tw-ring-offset-2 tw-rounded">
                    <span *ngIf="showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility_off</mat-icon> Hide
                      password
                    </span>
                    <span *ngIf="!showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility</mat-icon> Show password
                    </span>
                  </button>
                </div>
              </div>

              <div>
                <label
                  for="password_confirm"
                  class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="password_confirm"
                  formControlName="confirmPasswordFormControl"
                  placeholder="••••••••"
                  [type]="showPassword ? 'text' : 'password'"
                  required
                  class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
                <div
                  class="tw-flex tw-items-center tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50 tw-p-4 tw-mt-4"
                  role="alert"
                  *ngIf="checkPasswords(form)">
                  Passwords do not match.
                </div>
              </div>
              <!-- <mat-checkbox formControlName="termsOfUse" name="termsOfUse">Agreed to terms of use and privacy statements</mat-checkbox> -->

              <button
                type="submit"
                class="tw-w-full tw-text-white tw-bg-primary_purple-600 hover:tw-bg-primary_purple-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary_purple-300 tw-font-medium tw-rounded tw-text-sm tw-px-5 tw-py-2.5 tw-text-center">
                Get Started!
              </button>

              <div
                class="tw-flex tw-items-center tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50 tw-p-4 tw-mt-2"
                role="alert"
                *ngIf="signUpEmailError">
                Please use a company email to sign up
              </div>
              <div
                class="tw-flex tw-items-center tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50 tw-p-4 tw-mt-2"
                role="alert"
                *ngIf="validationError">
                Please check your password and try again
              </div>

              <div>
                <div
                  *ngIf="inviteType != 'moneyball_onboarding'"
                  class="tw-text-sm tw-font-light tw-text-gray-700">
                  Already a user?
                  <a
                    href="javascript:void(0)"
                    (click)="navigateToLogin()"
                    class="tw-font-medium tw-text-primary_purple-600 hover:tw-underline tw-inline-block">
                    Log in
                  </a>
                </div>
                <a
                  *ngIf="inviteType != 'moneyball_onboarding'"
                  (click)="forgotPassword()"
                  href="javascript:void(0)"
                  class="tw-text-sm tw-font-medium tw-text-primary_purple-600 hover:tw-underline tw-inline-block tw-mb-8">
                  Forgot password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>

      <!-- invite error -->
      <mat-card
        *ngIf="inviteError"
        id="login"
        class="tw-flex tw-items-center tw-gap-4 tw-text-base tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50 tw-p-4 tw-mt-2 tw-mb-4"
        role="alert">
        <img class="tw-h-8" src="../../assets/icons/error.svg" alt="error" />
        <span>
          {{inviteErrorMessage}}
        </span>
      </mat-card>

      <!-- log in -->
      <section
        *ngIf="isActionLogin"
        class="bg-gray-50 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-8 tw-mx-auto lg:tw-py-0 lg:tw-min-w-[500px]">
        <a routerLink="/" class="tw-mb-6 lg:tw-mb-8">
          <img src="../../assets/img/imagotype2.svg" alt="logo" />
        </a>
        <div
          class="tw-w-full tw-bg-white tw-rounded-md tw-shadow md:tw-mt-0 sm:tw-max-w-md xl:tw-p-0">
          <div class="tw-p-6 tw-space-y-4 md:tw-space-y-6 sm:tw-p-8">
            <h1
              class="tw-text-xl tw-font-bold tw-leading-tight tw-tracking-tight tw-text-gray-800 md:tw-text-2xl">
              Log in to your account
            </h1>
            <form
              class="tw-space-y-4 md:tw-space-y-6"
              [formGroup]="loginform"
              (submit)="onSubmit($event)">
              <div>
                <label
                  for="email"
                  class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  placeholder="name@company.com"
                  required
                  class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
              </div>
              <div>
                <div>
                  <label
                    for="password"
                    class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    formControlName="password"
                    id="password"
                    placeholder="••••••••"
                    [type]="showPassword ? 'text' : 'password'"
                    required
                    class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded focus:tw-ring-primary_purple-600 focus:tw-border-primary_purple-600 tw-block tw-w-full tw-p-2.5" />
                </div>
                <div class="tw-flex tw-items-center tw-justify-between tw-mt-3">
                  <button
                    type="button"
                    (click)="togglePasswordVisibility()"
                    class="tw-text-gray-700 hover:tw-text-primary_purple-700 tw-transition-all tw-text-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary_purple-600 focus:tw-ring-offset-2 tw-rounded">
                    <span *ngIf="showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility_off</mat-icon> Hide
                      password
                    </span>
                    <span *ngIf="!showPassword" class="tw-flex tw-items-center tw-gap-1">
                      <mat-icon class="tw-w-5 tw-h-5 tw-text-sm">visibility</mat-icon> Show password
                    </span>
                  </button>
                  <a
                    href="javascript:void(0)"
                    (click)="forgotPassword()"
                    class="tw-text-sm tw-font-medium tw-text-primary_purple-600 hover:tw-underline hover:tw-text-primary_purple-700 tw-transition-all">
                    Forgot password?
                  </a>
                </div>
              </div>

              <button
                type="submit"
                [disabled]="loading"
                [ngClass]="{
                  'tw-opacity-65 tw-cursor-not-allowed': loading,
                  'hover:tw-bg-primary_purple-700 focus:tw-ring-4 focus:tw-ring-primary_purple-300': !loading
                }"
                class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-text-white tw-bg-primary_purple-600 tw-font-medium tw-rounded tw-text-sm tw-px-5 tw-py-2.5 tw-text-center">
                <mat-spinner
                  *ngIf="loading"
                  class="spinner tw-relative"
                  diameter="25"></mat-spinner>
                Log in
              </button>

              <div
                class="tw-flex tw-items-center tw-p-4 tw-mb-4 tw-text-sm tw-text-red-800 tw-border tw-border-red-300 tw-rounded-lg tw-bg-red-50"
                role="alert"
                *ngIf="error">
                Sorry, {{ error.error }}.
              </div>

              <p class="tw-text-sm tw-font-light tw-text-gray-700">
                Don’t have an account yet? Ask your administrator for an invite.
              </p>
              <a
                href="/onboarding"
                class="tw-font-medium tw-text-primary_purple-600 hover:tw-underline tw-inline-block tw-mb-8">
                Or get started for free.
              </a>
            </form>
          </div>
        </div>
      </section>
    </main>

    <!-- Footer desktop -->
    <footer
      class="tw-flex tw-items-center tw-flex-wrap tw-gap-6 lg:tw-gap-8 tw-bg-gray-800 tw-text-gray-400 tw-py-4 tw-text-center tw-mt-auto tw-px-4 lg:tw-bg-gray-100 lg:tw-text-gray-500">
      <div>©2024 Live Data Technologies, Inc.</div>
      <nav class="tw-flex tw-justify-start tw-space-x-6">
        <a
          href="https://www.livedatatechnologies.com/privacy-policy"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white lg:tw-text-gray-700">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Privacy Policy
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
        <a
          href="https://www.livedatatechnologies.com/terms-of-service"
          target="_blank"
          class="tw-flex tw-items-center tw-text-white lg:tw-text-gray-700">
          <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
            Terms of Use
          </span>
          <mat-icon class="tw-text-base">north_east</mat-icon>
        </a>
      </nav>
    </footer>
  </div>

  <div
    class="tw-relative tw-hidden tw-w-0 tw-flex-1 tw-bg-gradient-to-r tw-from-primary_purple-700 tw-to-blue-800 lg:tw-flex lg:tw-flex-col lg:tw-justify-center lg:tw-py-10 lg:tw-px-12 xl:tw-px-16">
    <!-- if img doesn't load, display this -->
    <h2 class="tw-text-4xl tw-font-extrabold tw-text-white">
      Unlock The Power Of Millions Of Job Changes
    </h2>
    <p class="tw-text-gray-300 tw-my-4 tw-text-lg">
      Our platform monitors millions of job changes monthly at the individual-level. Get the data
      and insights you need, how you need them.
    </p>
    <img
      class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
      src="../../../assets/img/globe-istock-md-1369px.jpg"
      loading="lazy"
      alt="global data flow and connectivity, blue planet Earth" />
  </div>
</div>
