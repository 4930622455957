// tslint:disable: max-line-length
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AccountManagementComponent } from './account-management/account-management.component';
import { HeapService } from './api-client/heap.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { MainPageComponent } from './main-page/main-page.component';
import { SharedModule } from './shared/shared.module';
import { FlatfileAdapterModule } from '@flatfile/angular';
import { LedgerComponent } from './ledger/ledger.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { LedgerImportComponent } from './ledger/ledger-import/ledger-import.component';
import { LedgerContactsComponent } from './ledger/ledger-contacts/ledger-contacts.component';
import { AgGridModule } from 'ag-grid-angular';
import { LedgersListComponent } from './ledger/ledgers-list/ledgers-list.component';
import { AuthService } from './auth/service/auth.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import {
  ApiModule as IdentityServiceApi,
  BASE_PATH as ID_BASE_PATH,
} from 'ldt-identity-service-api';
import { ApiModule as LedgerServiceApi, BASE_PATH as LD_BASE_PATH } from 'ldt-ledger-service-api';
import {
  ApiModule as LedgerServiceApiV2,
  BASE_PATH as LD_BASE_PATH_V2,
} from 'ldt-ledger-service-api-v2';
import {
  ApiModule as OnboardingServiceApi,
  BASE_PATH as OB_BASE_PATH,
} from 'ldt-onboarding-service-api';
import { ApiModule as CleanServiceApi, BASE_PATH as CL_BASE_PATH } from 'ldt-clean-service-api';
import { ApiModule as BillingServiceApi, BASE_PATH as BL_BASE_PATH } from 'ldt-billing-service-api';
import {
  ApiModule as DWReaderServiceApi,
  BASE_PATH as DWR_BASE_PATH,
} from 'ldt-dw-reader-service-api';
import { ApiModule as DevDeploysApi, BASE_PATH as DD_BASE_PATH } from 'ldt-dev-deploys-api';
import { ApiModule as MoneyballApi, BASE_PATH as MB_BASE_PATH } from 'ldt-moneyball-api';
import { ApiModule as PeopleApi, BASE_PATH as PP_BASE_PATH } from 'ldt-people-api';
import { ApiModule as DataDeliveryApi, BASE_PATH as DDE_BASE_PATH } from 'ldt-data-deliveries-api';
import { environment } from 'src/environments/environment';
import { IdentityComponent } from './admin/identity/identity.component';
import { IdentityOrgsComponent } from './admin/identity/orgs/identity-orgs.component';
import { IdentityUsersComponent } from './admin/identity/users/identity-users.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { BtnCellRenderer } from './shared/ag-grid-button/button-cell-renderer.component';
import { TeamComponent } from './team/team.component';
import { TeamUsersComponent } from './team/team-users/team-users.component';
import { TeamServiceAccountsComponent } from './team/team-service-accounts/team-service-accounts.component';
import { HeaderAcceptsInterceptor } from './api-client/header-response-type.interceptor';
import { HeaderAppVersionInterceptor } from './api-client/header-app-version';
import { UserCreateDialogComponent } from './admin/identity/users/user-create-dialog/user-create-dialog.component';
import { TeamInvitationsComponent } from './team/team-invitations/team-invitations.component';
import { IdentityOrgCreateDialogComponent } from './admin/identity/orgs/identity-org-create-dialog/identity-org-create-dialog.component';
import { IdentityOrgDetailsComponent } from './admin/identity/org-details/identity-org-details.component';
import { InvitationsCreateDialogComponent } from './team/invitations-create-dialog/invitations-create-dialog.component';
import { AdminLedgerComponent } from './admin/ledger/ledger.component';
import { SaCreateDialogComponent } from './team/team-service-accounts/sa-create-dialog/sa-create-dialog.component';
import { AdminLedgerSettingsComponent } from './admin/ledger/settings/settings.component';
import { PeopleComponent } from './admin/ledger/people/people.component';
import { TimeagoModule } from 'ngx-timeago';
import { ManagedJobsComponent } from './admin/clean/managed-jobs/managed-jobs.component';
import { CleanComponent } from './admin/clean/clean.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullMaterialModule } from './material/material-module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { JobsComponent } from './jobs/jobs.component';
import { UploadModule } from './jobs/upload/upload.module';
import * as Sentry from '@sentry/angular-ivy';
import { SettingsBillingComponent } from './settings/billing//billing.component';
import { SettingsGeneralComponent } from './settings/general/general.component';
import { SettingsComponent } from './settings/settings.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { LedgerJobCellRendererComponent } from './ledger/ledger-contacts/ledger-job-cell-renderer/ledger-job-cell-renderer.component';
import { LedgerOverviewComponent } from './ledger/ledger-overview/ledger-overview.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MjCreateDialogComponent } from './admin/clean/managed-jobs/mj-create-dialog/mj-create-dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EmployeesComponent } from './admin/identity/employees/employees.component';
import { ServiceRoleRendererComponent } from './admin/identity/employees/service-role-renderer/service-role-renderer.component';
import { AddEmployeeRoleDialogComponent } from './admin/identity/employees/add-role-dialog/add-role-dialog.component';
import { AddEmployeeDialogComponent } from './admin/identity/employees/add-employee-dialog/add-employee-dialog.component';
import { SaNewSecretDialogComponent } from './team/team-service-accounts/sa-new-secret-dialog/sa-new-secret-dialog.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FirehoseComponent } from './admin/firehose/firehose.component';
import { DataWarehouseComponent } from './data-warehouse/data-warehouse.component';
import { CustomSearchComponent } from './data-warehouse/custom-search/custom-search.component';
import { StatsComponent } from './data-warehouse/stats/stats.component';
import { DetailsComponent } from './data-warehouse/details/details.component';
import { MoneyballComponent } from './moneyball/moneyball.component';
import { GrowComponent } from './grow/grow.component';
import { InspectComponent } from './data-warehouse/inspect/inspect.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { HotkeyModule } from 'angular2-hotkeys';
import { FieldSelectorDialogComponent } from './shared/field-selector-dialog/field-selector-dialog.component';
import { LedgerVersionCompareComponent } from './ledger/ledger-version-compare/ledger-version-compare.component';
import { GrowService, BASE_PATH as GS_BASE_PATH } from './grow/grow.service';
import { OsQueryComponent } from './data-warehouse/os-query/os-query.component';
import { ServiceAccountsComponent } from './admin/identity/service-accounts/service-accounts.component';
import { ServiceAccountCreateDialogComponent } from './admin/identity/service-accounts/service-account-create-dialog/service-account-create-dialog.component';
import { TechnoTargetComponent } from './techno-target/techno-target.component';
import { CreateLedgerDialogComponent } from './ledger/create-ledger-dialog/create-ledger-dialog.component';
import { CleanNowDialogComponent } from './admin/ledger/people/clean-now-dialog/clean-now-dialog.component';
import { Onboardingv2Component } from './onboardingv2/onboardingv2.component';
import { StepperComponent } from './onboardingv2/stepper/stepper.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldUpdatedSignalComponent } from './data-warehouse/inspect/field-updated-signal/field-updated-signal.component';
import { LedgerContactsV2Component } from './ledger/ledger-contacts-v2/ledger-contacts-v2/ledger-contacts-v2.component';
import { CompanyDialogComponent } from './data-warehouse/inspect/company-dialog/company-dialog.component';
import { V2AuditComponent } from './ledger/v2-audit/v2-audit.component';
import { JobsTableCellRendererComponent } from './shared/jobs-table-cell-renderer/jobs-table-cell-renderer.component';
import { JobsTableComponent } from './shared/jobs-table/jobs-table.component';
import { LedgerOverviewV2Component } from './ledger/ledger-overview-v2/ledger-overview-v2.component';
import { StagingFirehoseComponent } from './admin/staging-firehose/staging-firehose.component';
import { PeopleDetailsComponent } from './people/details/details.component';
import { DWPeopleComponent } from './people/people.component';
import { AliasesComponent } from './data-warehouse/aliases/aliases.component';
import { AdminStreamsComponent } from './admin/onboarding/onboarding.component';
import { StreamsTrialComponent } from './admin/onboarding/streams-trial/streams-trial.component';
import { PeopleFindComponent } from './people/find/find.component';
import { AvailableIntegrationsComponent } from './integrations/available-integrations/available-integrations.component';
import { ConfiguredIntegrationsComponent } from './integrations/configured-integrations/configured-integrations.component';
import { IntegrationDialogComponent } from './integrations/available-integrations/integration-dialog/integration-dialog.component';
import { DataDeliveriesComponent } from './admin/data-deliveries/data-deliveries.component';
import { CompaniesComponent } from './data-warehouse/companies/companies.component';
import { StreamsCompaniesComponent } from './admin/onboarding/streams-companies/streams-companies.component';
import { FiltersComponent } from './people/filters/filters.component';
import { SimpleFilterComponent } from './people/filters/simple-filter/simple-filter.component';
import { TestStatusDialogComponent } from './integrations/configured-integrations/test-status-dialog/test-status-dialog.component';
import { ConfigInfoComponent } from './integrations/helper-components/config-info/config-info.component';
import { DetailsTooltipCellComponent } from './integrations/helper-components/details-tooltip-cell/details-tooltip-cell.component';
import { DashboardComponent } from './admin/onboarding/dashboard/dashboard.component';
import { DestinationsComponent } from './admin/data-deliveries/destinations/destinations.component';
import { DestinationTypesComponent } from './admin/data-deliveries/destination-types/destination-types.component';
import { ImportStatusComponent } from './admin/ledger/import-status/import-status.component';
import { MoneyballBattleComponent } from './moneyball-battle/moneyball-battle.component';
import { CompanySearchComponent } from './shared/company-search/company-search.component';
import { ReportDefinitionsComponent } from './admin/data-deliveries/report-definitions/report-definitions.component';
import { ReportDeliveriesComponent } from './admin/data-deliveries/report-deliveries/report-deliveries.component';
import { ScheduledReportsComponent } from './admin/data-deliveries/scheduled-reports/scheduled-reports.component';
import { QuickBuildsComponent } from './people/filters/quick-builds/quick-builds.component';
import { FilterGroupComponent } from './people/filters/filter-group/filter-group.component';
import { FilterChipsComponent } from './people/filters/filter-chips/filter-chips.component';
import { ReportSourcesComponent } from './admin/data-deliveries/report-sources/report-sources.component';
import { AdminMoneyballComponent } from './admin/moneyball/moneyball.component';
import { FilterCompanySearchComponent } from './people/filters/filter-company-search/filter-company-search.component';
import { AddQuickBuildModalComponent } from './people/filters/add-quick-build-modal/add-quick-build-modal.component';
import { ServiceCreateDialogComponent } from './admin/identity/service-accounts/service-create-dialog/service-create-dialog.component';
import { AdminBillingComponent } from './admin/billing/billing.component';
import { AdminBillingProductsComponent } from './admin/billing/products/products.component';
import { AdminBillingSubscriptionsComponent } from './admin/billing/subscriptions/subscriptions.component';
import { OrgSettingsEditorComponent } from './admin/shared/org-settings-editor/org-settings-editor.component';
import { OrgSettingsEditorModalComponent } from './admin/shared/org-settings-editor/org-settings-editor-modal.component';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    AccountManagementComponent,
    LedgerComponent,
    DeleteConfirmationComponent,
    LedgerImportComponent,
    LedgerContactsComponent,
    LedgersListComponent,
    IdentityComponent,
    IdentityOrgsComponent,
    IdentityUsersComponent,
    IdentityOrgCreateDialogComponent,
    IdentityOrgDetailsComponent,
    BtnCellRenderer,
    TeamComponent,
    TeamUsersComponent,
    TeamServiceAccountsComponent,
    UserCreateDialogComponent,
    TeamInvitationsComponent,
    InvitationsCreateDialogComponent,
    AdminLedgerComponent,
    SaCreateDialogComponent,
    AdminLedgerSettingsComponent,
    PeopleComponent,
    ManagedJobsComponent,
    CleanComponent,
    JobsComponent,
    SettingsBillingComponent,
    SettingsGeneralComponent,
    SettingsComponent,
    IntegrationsComponent,
    LedgerJobCellRendererComponent,
    LedgerOverviewComponent,
    MjCreateDialogComponent,
    EmployeesComponent,
    ServiceRoleRendererComponent,
    AddEmployeeRoleDialogComponent,
    AddEmployeeDialogComponent,
    SaNewSecretDialogComponent,
    FirehoseComponent,
    DataWarehouseComponent,
    CustomSearchComponent,
    StatsComponent,
    DetailsComponent,
    MoneyballComponent,
    GrowComponent,
    InspectComponent,
    FieldSelectorDialogComponent,
    LedgerVersionCompareComponent,
    OsQueryComponent,
    ServiceAccountsComponent,
    ServiceAccountCreateDialogComponent,
    TechnoTargetComponent,
    CreateLedgerDialogComponent,
    CleanNowDialogComponent,
    Onboardingv2Component,
    StepperComponent,
    FieldUpdatedSignalComponent,
    LedgerContactsV2Component,
    CompanyDialogComponent,
    V2AuditComponent,
    JobsTableCellRendererComponent,
    JobsTableComponent,
    LedgerOverviewV2Component,
    StagingFirehoseComponent,
    PeopleDetailsComponent,
    DWPeopleComponent,
    AliasesComponent,
    AdminStreamsComponent,
    StreamsTrialComponent,
    PeopleFindComponent,
    AvailableIntegrationsComponent,
    ConfiguredIntegrationsComponent,
    IntegrationDialogComponent,
    DataDeliveriesComponent,
    CompaniesComponent,
    StreamsCompaniesComponent,
    FiltersComponent,
    SimpleFilterComponent,
    TestStatusDialogComponent,
    ConfigInfoComponent,
    DetailsTooltipCellComponent,
    DashboardComponent,
    DestinationsComponent,
    DestinationTypesComponent,
    ImportStatusComponent,
    CompanySearchComponent,
    MoneyballBattleComponent,
    ReportDefinitionsComponent,
    ReportDeliveriesComponent,
    ScheduledReportsComponent,
    QuickBuildsComponent,
    FilterGroupComponent,
    FilterChipsComponent,
    ReportSourcesComponent,
    AdminMoneyballComponent,
    FilterCompanySearchComponent,
    AddQuickBuildModalComponent,
    ServiceCreateDialogComponent,
    AdminBillingComponent,
    AdminBillingProductsComponent,
    AdminBillingSubscriptionsComponent,
    OrgSettingsEditorComponent,
    OrgSettingsEditorModalComponent,
  ],
  imports: [
    FullMaterialModule,
    BrowserModule,
    RouterModule,
    AuthModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlatfileAdapterModule,
    AgGridModule,
    IdentityServiceApi,
    LedgerServiceApi,
    LedgerServiceApiV2,
    CleanServiceApi,
    OnboardingServiceApi,
    BillingServiceApi,
    DWReaderServiceApi,
    DevDeploysApi,
    MoneyballApi,
    PeopleApi,
    DataDeliveryApi,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          'dw.dwh-pipeline.staging.gotlivedata.io',
          'gotlivedata.io',
          'alternate.gotlivedata.io',
          'staging.gotlivedata.io',
          'localhost:5000',
          'dwh-pipeline.gotlivedata.io',
          'deliveries.dwh-pipeline.staging.gotlivedata.io',
          'dw-test.dwh-pipeline.gotlivedata.io',
        ],
        disallowedRoutes: [/.*\/session$/],
      },
    }),
    TimeagoModule.forRoot(),
    BrowserAnimationsModule,
    RecaptchaV3Module,
    UploadModule,
    NgxSliderModule,
    HighchartsChartModule,
    NgxDaterangepickerMd.forRoot(),
    ClipboardModule,
    NgxJsonViewerModule,
    HotkeyModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    AuthService,
    GrowService,
    HeapService,
    {
      provide: ID_BASE_PATH,
      useValue: environment.identityApiBasePath,
    },
    {
      provide: LD_BASE_PATH,
      useValue: environment.ledgerApiBasePath,
    },
    {
      provide: LD_BASE_PATH_V2,
      useValue: environment.ledgerApiBasePathV2,
    },
    {
      provide: GS_BASE_PATH,
      useValue: environment.growApiBasePath,
    },
    {
      provide: CL_BASE_PATH,
      useValue: environment.cleanApiBasePath,
    },
    {
      provide: OB_BASE_PATH,
      useValue: environment.onboardingApiBasePath,
    },
    {
      provide: BL_BASE_PATH,
      useValue: environment.billingApiBasePath,
    },
    {
      provide: DWR_BASE_PATH,
      useValue: environment.dwReaderApiBasePath,
    },
    {
      provide: DD_BASE_PATH,
      useValue: environment.devDeploysApiBasePath,
    },
    {
      provide: MB_BASE_PATH,
      useValue: environment.moneyballApiBasePath,
    },
    {
      provide: PP_BASE_PATH,
      useValue: environment.peopleApiBasePath,
    },
    {
      provide: DDE_BASE_PATH,
      useValue: environment.dataDeliveryApiBasePath,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderAcceptsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderAppVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
