import { SavedList, SavedListType } from './saved-lists-models';

export const prebuiltLists: SavedList[] = [
  {
    itemType: SavedListType.Companies,
    id: 'c4379ccc-f29c-4e99-bcf8-8e36fa318ae5',
    name: 'Big Tech',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-28a3bf17-1c92-48b2-add2-9d3a7f0921c7', group: true },
      { id: 'LDC-1-5013b123-f675-4c07-83b4-a102c99d090b', group: true },
      { id: 'LDC-1-52d98ff7-589b-44f8-ba61-a033b9894cae', group: true },
      { id: 'LDC-1-40896ef5-be2a-4725-9899-da941deddf77', group: true },
      { id: 'LDC-1-41f6f15c-31d8-4f2c-9b09-06e4d9b15604', group: true },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '09a47a9a-e8fa-4392-83d5-cb3b5e0fae46',
    name: 'Chips',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-9994cf7e-eef4-4717-b6d3-4af3eb4cba1c', group: true },
      { id: 'LDC-1-4a1fe207-4d24-44e5-a062-c8554e60d418', group: true },
      { id: 'LDC-1-91ba2fb3-3b0a-48e4-8e23-0624a9aafcc3', group: true },
      { id: 'LDC-1-813a9eba-2eef-4231-b4ed-7e1c08462e9e', group: true },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'c72b0a2e-5e99-421a-8e64-32d685b69f23',
    name: 'Banks',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-705d6b3f-491b-49cb-9cc2-98070c264d70', group: true },
      { id: 'LDC-1-8a6c11e3-59b4-4242-9063-444e17a73b68', group: true },
      { id: 'LDC-1-fbd67dbb-f218-4de7-a349-c45962e898bf', group: true },
      { id: 'LDC-1-b9b1b0f7-9802-4c89-94bb-51c0fd7e5fc0', group: true },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'ed96ada4-ecab-4e9e-867d-1b306b40efcc',
    name: 'Unicorns',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-ba3f39f9-1baf-4232-b6bc-b006a6862e6e', group: false },
      { id: 'LDC-1-d5901ffc-4ef6-4a10-8542-1d1fbcf42a4a', group: false },
      { id: 'LDC-1-82fdfc40-69b6-479e-8b2d-553c2df0352d', group: false },
      { id: 'LDC-1-056d16a2-bbf0-4474-bd35-ab4a46376085', group: false },
      { id: 'LDC-1-332eb893-4100-4cf8-80ba-7e412eef3655', group: false },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '89046b9c-0143-42d8-9314-ee796155da43',
    name: 'HR Software',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-b0e7f787-a84f-4ed6-b794-bc4d06fffa50', group: true },
      { id: 'LDC-1-837a4ee1-1d21-46c4-a6e0-025ef8f18285', group: false },
      { id: 'LDC-1-1648c359-b2e2-4c10-b64b-217b210e86e6', group: false },
      { id: 'LDC-1-77b5d61b-f622-4b49-b376-bb88f46ed95b', group: true },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '89046b9c-0143-42d8-9314-ee796155da43',
    name: 'VC Top 10 - Vista',
    createdAt: '2024-09-01T00:00:00Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      { id: 'LDC-1-b0e7f787-a84f-4ed6-b794-bc4d06fffa50', group: false },
      { id: 'LDC-1-837a4ee1-1d21-46c4-a6e0-025ef8f18285', group: false },
      { id: 'LDC-1-1648c359-b2e2-4c10-b64b-217b210e86e6', group: false },
      { id: 'LDC-1-77b5d61b-f622-4b49-b376-bb88f46ed95b', group: false },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '7ba77835-7820-4b65-a47e-eaea799b2f30',
    name: 'VC Top 10 - 2 Sigma',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-1755c357-ea68-4684-afb6-31b4b0630c69',
        group: false,
      },
      {
        id: 'LDC-1-4e3a0d1b-5eab-4ea4-b409-33e54698b307',
        group: false,
      },
      {
        id: 'LDC-1-6f43170e-da51-4670-bd19-9db5f7966c4b',
        group: false,
      },
      {
        id: 'LDC-1-a90ac0d7-115e-417c-b1e4-a2b016e11759',
        group: false,
      },
      {
        id: 'LDC-1-0751fa1b-a773-479c-8a68-98a4231c5e01',
        group: false,
      },
      {
        id: 'LDC-1-c93679fb-3066-4557-bd33-5bfa3e62a540',
        group: false,
      },
      {
        id: 'LDC-1-88bf2dd2-89a6-42eb-ae78-300974b65a96',
        group: false,
      },
      {
        id: 'LDC-1-9a6ffebc-45b3-4fd7-9b70-6b4b900797c5',
        group: false,
      },
      {
        id: 'LDC-1-5f0882be-781d-48d7-b6fc-2925bb4da5fa',
        group: false,
      },
      {
        id: 'LDC-1-a5591445-0fc5-46fd-a3a3-4a76c382fe2e',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '01adf50d-a388-48f3-9102-7b6aaf8d72fe',
    name: 'VC Top 10 - 8VC',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-acecfff0-0494-4b05-8f84-cf130bf5d739',
        group: false,
      },
      {
        id: 'LDC-1-d3941974-dd3a-4c8a-8443-4d32afd4321e',
        group: false,
      },
      {
        id: 'LDC-1-e2a31f02-26a7-4ed5-a363-3ec9335c7bd3',
        group: false,
      },
      {
        id: 'LDC-1-2fe282dd-6c7c-4743-a629-9825fd893ce7',
        group: false,
      },
      {
        id: 'LDC-1-8257e322-edc1-425f-8a9a-bcae8ebafdc1',
        group: false,
      },
      {
        id: 'LDC-1-f2592971-1451-4e49-92bb-d4e5a82e6fa5',
        group: false,
      },
      {
        id: 'LDC-1-d28f2990-fdcd-4c40-9b08-ee3c8e862c20',
        group: false,
      },
      {
        id: 'LDC-1-86543061-1247-4c31-88ba-87637dd098af',
        group: false,
      },
      {
        id: 'LDC-1-236d34f8-deee-4f16-bdec-209f4512c989',
        group: false,
      },
      {
        id: 'LDC-1-45d9de23-d03c-4dba-89ab-278cb6a5e4ee',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '908f780b-1b84-456a-a391-20dadad37b94',
    name: 'VC Top 10 - GV',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-ba3f39f9-1baf-4232-b6bc-b006a6862e6e',
        group: false,
      },
      {
        id: 'LDC-1-6612e411-08d5-4c62-8594-e7c8eaf5e4df',
        group: false,
      },
      {
        id: 'LDC-1-9878922f-84ff-4938-be79-ea950c4a8145',
        group: false,
      },
      {
        id: 'LDC-1-e2a31f02-26a7-4ed5-a363-3ec9335c7bd3',
        group: false,
      },
      {
        id: 'LDC-1-e274820a-6075-4419-86fc-a91075edad26',
        group: false,
      },
      {
        id: 'LDC-1-e8b2b7bb-49e1-41b1-9e3e-babec8eb1f18',
        group: false,
      },
      {
        id: 'LDC-1-d308116f-50f3-4b9e-9a1a-4f68e68a3bed',
        group: false,
      },
      {
        id: 'LDC-1-56f4d835-cbaa-4f61-8fae-f5bac80356e7',
        group: false,
      },
      {
        id: 'LDC-1-55b6108d-28aa-409b-b570-9cd0e7eff767',
        group: false,
      },
      {
        id: 'LDC-1-d38e002c-ad36-422a-a8bb-1e4755c36f2b',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '2b5462b9-7318-4f83-8d13-4f6f5c2c2a3b',
    name: 'VC Top 10 - Madrona',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-e274820a-6075-4419-86fc-a91075edad26',
        group: false,
      },
      {
        id: 'LDC-1-e51cedef-49d0-443a-9fbb-ee7007f4762d',
        group: false,
      },
      {
        id: 'LDC-1-41169ea6-df7e-4570-bf0a-50c0589981cf',
        group: false,
      },
      {
        id: 'LDC-1-79060806-36d4-41b6-b704-3bed48431ad8',
        group: false,
      },
      {
        id: 'LDC-1-d64afed5-28ae-45bd-b4ba-8a2b25c6d720',
        group: false,
      },
      {
        id: 'LDC-1-1c72f6b5-3674-4ecf-9e4d-1f19c365aaac',
        group: false,
      },
      {
        id: 'LDC-1-2ccaa7a1-55ac-4c3e-a6b8-3557c53c5684',
        group: false,
      },
      {
        id: 'LDC-1-804e5d82-80a3-4a6c-bf32-4ec4fc238c39',
        group: false,
      },
      {
        id: 'LDC-1-a08a5461-989f-4c42-b949-10ae72e80659',
        group: false,
      },
      {
        id: 'LDC-1-645f9c27-65d3-4013-968f-bd68a569946d',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'fae65e34-3198-4cd4-a0e9-50d8c0be76c4',
    name: 'VC Top 10 - Pear',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-9878922f-84ff-4938-be79-ea950c4a8145',
        group: false,
      },
      {
        id: 'LDC-1-e2a31f02-26a7-4ed5-a363-3ec9335c7bd3',
        group: false,
      },
      {
        id: 'LDC-1-4aa5b4cc-932b-431c-a105-c05344c626f8',
        group: false,
      },
      {
        id: 'LDC-1-086ca5c4-0ae3-4316-bdc5-3994959f0969',
        group: false,
      },
      {
        id: 'LDC-1-6915ec7c-d559-4aec-9e51-aed7a5dffc0c',
        group: false,
      },
      {
        id: 'LDC-1-e9eb68aa-7741-461c-a212-e231eb04e6ea',
        group: false,
      },
      {
        id: 'LDC-1-757ccda5-ff4b-4726-b6ac-e9c0722ad8e8',
        group: false,
      },
      {
        id: 'LDC-1-1f1f782a-cd3a-4c38-a78a-02a1cc76ca85',
        group: false,
      },
      {
        id: 'LDC-1-5f90a3ec-1513-493a-97db-e9e51e7954a8',
        group: false,
      },
      {
        id: 'LDC-1-6f9646e9-3092-4333-8e3f-585f880a0288',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'fe323e47-a0b3-40e2-87f5-49cd2686e3fc',
    name: 'VC Top 10 - Emergence',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-21d9a939-ecb1-4b07-b9c9-d06acc127576',
        group: false,
      },
      {
        id: 'LDC-1-9878922f-84ff-4938-be79-ea950c4a8145',
        group: false,
      },
      {
        id: 'LDC-1-deb527fa-cb70-41f3-8522-406b446d652a',
        group: false,
      },
      {
        id: 'LDC-1-8d00ae1e-617f-4561-835b-8e4e6463017a',
        group: false,
      },
      {
        id: 'LDC-1-9213d0ca-c743-4e2b-b8ef-ff88df140459',
        group: false,
      },
      {
        id: 'LDC-1-45d9de23-d03c-4dba-89ab-278cb6a5e4ee',
        group: false,
      },
      {
        id: 'LDC-1-a0248ad8-0395-468d-8625-dccd280f6611',
        group: false,
      },
      {
        id: 'LDC-1-9e9f8672-5bda-4ac4-aa57-a3f6c7552192',
        group: false,
      },
      {
        id: 'LDC-1-aeff6c06-8a93-494f-bcaf-7ecc05c9b568',
        group: false,
      },
      {
        id: 'LDC-1-64a58d79-4ff8-4518-a478-6d7561229413',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '3d06e48a-b4c5-4fd1-a87c-0ebde00e6131',
    name: 'VC Top 10 - Accel',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-6eb21ff5-65c3-4d33-b156-20aae4bae02b',
        group: false,
      },
      {
        id: 'LDC-1-8d1e65be-59ff-4c68-a656-9c29a86a1128',
        group: false,
      },
      {
        id: 'LDC-1-d839777f-5528-48bd-9406-a415c449d09d',
        group: false,
      },
      {
        id: 'LDC-1-520d232a-2862-417d-897e-c0629155ce7d',
        group: false,
      },
      {
        id: 'LDC-1-d5592c3c-68e4-4637-8ae7-58912826fafc',
        group: false,
      },
      {
        id: 'LDC-1-a6067354-552d-4173-a553-30cccc41683a',
        group: false,
      },
      {
        id: 'LDC-1-02e6acd1-ce7b-4c70-809a-a7e3360a18c3',
        group: false,
      },
      {
        id: 'LDC-1-17f1f513-4f2e-4a88-955a-2001d0aeadea',
        group: false,
      },
      {
        id: 'LDC-1-0e9ae714-6ca4-4758-ae2d-3eac4110851c',
        group: false,
      },
      {
        id: 'LDC-1-23b5f189-112e-4abe-89fe-66a450f229d2',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'eb529a6f-4370-47fc-a1ad-a4aa76c5eadf',
    name: 'VC Top 10 - Bessemer',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-31d0a417-861f-4a59-81c8-8d352fad2d7b',
        group: false,
      },
      {
        id: 'LDC-1-80946680-7263-4ca8-bde3-985435efc341',
        group: false,
      },
      {
        id: 'LDC-1-012b429c-6e1d-41ec-95be-92fb51797b18',
        group: false,
      },
      {
        id: 'LDC-1-bf971aea-31fd-47fe-aebf-de27fd08af5c',
        group: false,
      },
      {
        id: 'LDC-1-88689a38-d9ee-45d1-a1fc-814a34f0a4bb',
        group: false,
      },
      {
        id: 'LDC-1-bd8a4680-d27e-4960-bf1a-0bce39601772',
        group: false,
      },
      {
        id: 'LDC-1-a7b574bc-7f5e-4674-b416-7ab1801e39a9',
        group: false,
      },
      {
        id: 'LDC-1-6b1f21ee-2769-4ec3-a94e-1f17d51ef7bb',
        group: false,
      },
      {
        id: 'LDC-1-520d232a-2862-417d-897e-c0629155ce7d',
        group: false,
      },
      {
        id: 'LDC-1-d308116f-50f3-4b9e-9a1a-4f68e68a3bed',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'ed3bbf37-a572-4f55-be95-58f5e8d308f5',
    name: 'VC Top 10 - Lightspeed',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-ba3f39f9-1baf-4232-b6bc-b006a6862e6e',
        group: false,
      },
      {
        id: 'LDC-1-aca284bc-1259-4574-aeee-1a59f19e3c0e',
        group: false,
      },
      {
        id: 'LDC-1-90e99111-7339-47a6-a20e-19e1d6411a0c',
        group: false,
      },
      {
        id: 'LDC-1-056d16a2-bbf0-4474-bd35-ab4a46376085',
        group: false,
      },
      {
        id: 'LDC-1-9e82a300-f61b-4cdb-8c3d-9ad396f356b9',
        group: false,
      },
      {
        id: 'LDC-1-d3941974-dd3a-4c8a-8443-4d32afd4321e',
        group: false,
      },
      {
        id: 'LDC-1-4fb7b474-1dda-411f-a6b8-a084a563544e',
        group: false,
      },
      {
        id: 'LDC-1-6843d555-9332-4972-8bed-00c6511d9c24',
        group: false,
      },
      {
        id: 'LDC-1-9622cdbc-04a3-4e8d-a8fa-ec9a5da35379',
        group: false,
      },
      {
        id: 'LDC-1-8c1df209-ba70-4e42-807e-8a7ad3afaaf3',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '8c1a234a-3a49-464d-8df3-ca3420345dd4',
    name: 'VC Top 10 - Bond',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-bf971aea-31fd-47fe-aebf-de27fd08af5c',
        group: false,
      },
      {
        id: 'LDC-1-c9355b77-fed3-48db-8692-1dfed46dcda7',
        group: false,
      },
      {
        id: 'LDC-1-4a9221db-112f-4f97-a0c3-d4224a20e5d0',
        group: false,
      },
      {
        id: 'LDC-1-b20836fe-19e4-497e-9e99-8629d2e430d9',
        group: false,
      },
      {
        id: 'LDC-1-2fa3d9f1-9f87-49a4-9265-d99e40248688',
        group: false,
      },
      {
        id: 'LDC-1-42905d66-0bdf-4ac1-87cf-881f4d8d5512',
        group: false,
      },
      {
        id: 'LDC-1-10215dd1-5368-429c-ae1d-fe04966eeeb2',
        group: false,
      },
      {
        id: 'LDC-1-69b5d0d7-e207-418d-9054-abce607b783b',
        group: false,
      },
      {
        id: 'LDC-1-689ddb1a-61f3-4d5d-a6e4-5165b5c84939',
        group: false,
      },
      {
        id: 'LDC-1-1a8b12dc-1828-4f8e-a4e7-72d9b019ba63',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '0243f119-e8ba-477b-b4f6-ba7e77b17d8c',
    name: 'VC Top 10 - Cowboy',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-52643cd7-3c1d-4520-b2a9-fcbf4535f3b8',
        group: false,
      },
      {
        id: 'LDC-1-602339e6-bad5-433f-96f6-5dff356c0852',
        group: false,
      },
      {
        id: 'LDC-1-a0248ad8-0395-468d-8625-dccd280f6611',
        group: false,
      },
      {
        id: 'LDC-1-c6c51f72-68c6-45f5-a337-430a8407b96a',
        group: false,
      },
      {
        id: 'LDC-1-edfce9f2-c5ba-4451-a5a9-eca6a4e39d4d',
        group: false,
      },
      {
        id: 'LDC-1-ef688039-13dd-4138-a860-d39f57e982d5',
        group: false,
      },
      {
        id: 'LDC-1-804e5d82-80a3-4a6c-bf32-4ec4fc238c39',
        group: false,
      },
      {
        id: 'LDC-1-12949ae7-601b-4eb2-8634-f01c11413188',
        group: false,
      },
      {
        id: 'LDC-1-db584933-126a-4c1f-ab99-06d1233da5de',
        group: false,
      },
      {
        id: 'LDC-1-5305df93-ee83-4261-adfb-b4b6ddc50543',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'bd84eca7-a210-4121-a31d-6f620c4370ae',
    name: 'VC Top 10 - Initialized',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-056d16a2-bbf0-4474-bd35-ab4a46376085',
        group: false,
      },
      {
        id: 'LDC-1-0b05965f-a79d-414a-ab60-78eb2232aa0f',
        group: false,
      },
      {
        id: 'LDC-1-e2a31f02-26a7-4ed5-a363-3ec9335c7bd3',
        group: false,
      },
      {
        id: 'LDC-1-4e161172-f91d-46ea-b812-aa21f9fe8a8f',
        group: false,
      },
      {
        id: 'LDC-1-8cfc4a0d-1406-4885-bc01-80b5933adb13',
        group: false,
      },
      {
        id: 'LDC-1-ef73260c-ef01-44a4-b62c-8f7de9158192',
        group: false,
      },
      {
        id: 'LDC-1-0b188760-18a1-4f7b-8e6b-2c459065b87a',
        group: false,
      },
      {
        id: 'LDC-1-00073f9d-af01-4b0c-95ed-be85c2d670a0',
        group: false,
      },
      {
        id: 'LDC-1-3610a563-77b4-4174-b93b-dfafff692fd3',
        group: false,
      },
      {
        id: 'LDC-1-e4864051-d72d-47ab-819e-41ee91422f2a',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'cb8f40c8-5726-47d2-ad84-046fe0605b11',
    name: 'VC Top 10 - Battery',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-567788a7-c63e-4ef9-8bad-2b7c03f637fd',
        group: false,
      },
      {
        id: 'LDC-1-88689a38-d9ee-45d1-a1fc-814a34f0a4bb',
        group: false,
      },
      {
        id: 'LDC-1-e274820a-6075-4419-86fc-a91075edad26',
        group: false,
      },
      {
        id: 'LDC-1-8c1df209-ba70-4e42-807e-8a7ad3afaaf3',
        group: false,
      },
      {
        id: 'LDC-1-fc01de06-3c9d-4412-bebc-dc65df0cd2b5',
        group: false,
      },
      {
        id: 'LDC-1-d79f5150-a9af-47da-a5eb-2f464737fb52',
        group: false,
      },
      {
        id: 'LDC-1-04cb0f7b-8753-4aa0-ae5d-f8daf9cbbe21',
        group: false,
      },
      {
        id: 'LDC-1-0888eae6-b4f2-4c55-af20-93f98860bc6d',
        group: false,
      },
      {
        id: 'LDC-1-a100f649-5dea-4d4a-a653-006e18e20ef2',
        group: false,
      },
      {
        id: 'LDC-1-b4cc6b43-79a0-42b9-8989-1eccd8fd9421',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '330a5459-b0fd-4e21-9f7c-c81755dd5209',
    name: 'VC Top 10 - Felicis',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-bf971aea-31fd-47fe-aebf-de27fd08af5c',
        group: false,
      },
      {
        id: 'LDC-1-9878922f-84ff-4938-be79-ea950c4a8145',
        group: false,
      },
      {
        id: 'LDC-1-e2a31f02-26a7-4ed5-a363-3ec9335c7bd3',
        group: false,
      },
      {
        id: 'LDC-1-9622cdbc-04a3-4e8d-a8fa-ec9a5da35379',
        group: false,
      },
      {
        id: 'LDC-1-fd42f7b8-40ee-4c45-b3e0-3191cc0c8d93',
        group: false,
      },
      {
        id: 'LDC-1-a04cd419-2204-4494-bdee-1939d7f51a7e',
        group: false,
      },
      {
        id: 'LDC-1-a5a09ca4-96d1-48c7-abfd-b703220d766e',
        group: false,
      },
      {
        id: 'LDC-1-99115a64-5a26-4889-ac4c-55e5be17c872',
        group: false,
      },
      {
        id: 'LDC-1-b608b4d0-2cb8-4b4d-9427-908df45312fa',
        group: false,
      },
      {
        id: 'LDC-1-52643cd7-3c1d-4520-b2a9-fcbf4535f3b8',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '57f2a299-aba0-4f8e-a5e4-23a6505238bc',
    name: 'VC Top 10 - Union Square',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-ba3f39f9-1baf-4232-b6bc-b006a6862e6e',
        group: false,
      },
      {
        id: 'LDC-1-8c1df209-ba70-4e42-807e-8a7ad3afaaf3',
        group: false,
      },
      {
        id: 'LDC-1-b78c013f-5c1e-42f6-a210-e7b2a6aab723',
        group: false,
      },
      {
        id: 'LDC-1-2fa3d9f1-9f87-49a4-9265-d99e40248688',
        group: false,
      },
      {
        id: 'LDC-1-073358fa-cb5a-4abf-8ba8-5ff51b6c2527',
        group: false,
      },
      {
        id: 'LDC-1-1020e1ed-74f0-42a6-a529-6f315b428eb6',
        group: false,
      },
      {
        id: 'LDC-1-9d2ee8b8-76eb-40ba-a397-b23a67790784',
        group: false,
      },
      {
        id: 'LDC-1-60a33c68-fe19-40cb-9db6-9ba41474fa38',
        group: false,
      },
      {
        id: 'LDC-1-e8d6edb3-9848-486a-bab0-d16375a00463',
        group: false,
      },
      {
        id: 'LDC-1-8f041e99-618a-478a-a03d-e676ae51dac5',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '5c3d2702-aeb5-4019-a4cb-f8b86336a277',
    name: 'VC Top 10 - Point 72',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-a91cba8d-13b9-4c0a-ac36-bebdf0e6ed01',
        group: false,
      },
      {
        id: 'LDC-1-a4380461-95a7-4a05-9fdd-a778500fd8b5',
        group: false,
      },
      {
        id: 'LDC-1-95d38f0b-1665-4068-ba8e-3309aaa1cc4d',
        group: false,
      },
      {
        id: 'LDC-1-349b4fb5-eb35-4001-9c8f-979a5c877fe7',
        group: false,
      },
      {
        id: 'LDC-1-0f75a305-649b-44ee-8e52-a6c8514b6e4e',
        group: false,
      },
      {
        id: 'LDC-1-6c2ef3c8-b396-4110-aeee-f55e70ae5461',
        group: false,
      },
      {
        id: 'LDC-1-05e700c0-9de0-455d-82b9-6edb19d8f2b8',
        group: false,
      },
      {
        id: 'LDC-1-9f5a8204-da68-4c0a-a597-393c4dfbb800',
        group: false,
      },
      {
        id: 'LDC-1-2d0def18-22a0-4253-b6cc-bc7beecd70d6',
        group: false,
      },
      {
        id: 'LDC-1-cdc45c90-5c5f-4511-ba86-70d60dbcca8c',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '52fa7169-94dc-4c20-90cd-6c21c0ddff44',
    name: 'VC Top 10 - Notion',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-04cb0f7b-8753-4aa0-ae5d-f8daf9cbbe21',
        group: false,
      },
      {
        id: 'LDC-1-4dc3b125-0476-4df6-8e8e-c52bcdc1fc04',
        group: false,
      },
      {
        id: 'LDC-1-b589105a-aa06-45cc-b5a1-4e279907c8e7',
        group: false,
      },
      {
        id: 'LDC-1-b40d642b-877c-4d20-bd47-ee8ed0b71784',
        group: false,
      },
      {
        id: 'LDC-1-9f5a8204-da68-4c0a-a597-393c4dfbb800',
        group: false,
      },
      {
        id: 'LDC-1-e30a58fc-2516-4474-a778-c9ad6c5e9e74',
        group: false,
      },
      {
        id: 'LDC-1-46f65135-863d-4c27-9bc6-e3be6156d9ee',
        group: false,
      },
      {
        id: 'LDC-1-674c6024-40a3-427b-863a-1acea5858550',
        group: false,
      },
      {
        id: 'LDC-1-a3d55db1-22fd-43c0-a85c-f12e89baa1aa',
        group: false,
      },
      {
        id: 'LDC-1-799ba36a-651d-4296-b2fa-e1ab564de244',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '240f6874-8999-4e15-93ec-bb85b0408b8b',
    name: 'VC Top 10 - Clearlake',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-7d62056c-7dd1-4d03-9e62-9ede4ab3b4e3',
        group: false,
      },
      {
        id: 'LDC-1-34e15490-baee-4c1f-97ba-45767452fb52',
        group: false,
      },
      {
        id: 'LDC-1-2101671e-5696-44b1-a74f-f48ee23157d8',
        group: false,
      },
      {
        id: 'LDC-1-607611a0-8101-4c85-a389-6cf7414ff5a5',
        group: false,
      },
      {
        id: 'LDC-1-21898290-48d7-4a17-b637-6d1d7dbfdc2e',
        group: false,
      },
      {
        id: 'LDC-1-a3e48ccf-7c05-40c0-945a-5db8101d21b2',
        group: false,
      },
      {
        id: 'LDC-1-dac7fcb4-d5c9-4391-99f8-5bf1e23c7333',
        group: false,
      },
      {
        id: 'LDC-1-157f487a-ce73-403a-abfe-4c7479c10477',
        group: false,
      },
      {
        id: 'LDC-1-a8d5921a-4104-4899-8060-1f0e6778fd45',
        group: false,
      },
      {
        id: 'LDC-1-0085c2a8-78f0-4412-9912-107d4e8e6dc5',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '27425310-600d-451f-a299-1acd706c6ce4',
    name: 'VC Top 10 - Craft',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-332eb893-4100-4cf8-80ba-7e412eef3655',
        group: false,
      },
      {
        id: 'LDC-1-3ff3d3c9-8a9e-40cc-a2d2-98f2d11ae93d',
        group: false,
      },
      {
        id: 'LDC-1-7f8516f4-1635-427c-961f-48fa9792629e',
        group: false,
      },
      {
        id: 'LDC-1-49e34d4d-0a94-4dcf-8240-758750ac4c16',
        group: false,
      },
      {
        id: 'LDC-1-fafedfcb-47b5-446d-b5cc-1682d12cb5f5',
        group: false,
      },
      {
        id: 'LDC-1-f51af7b5-f5ff-4d8d-8bd7-1ce2c075ea36',
        group: false,
      },
      {
        id: 'LDC-1-6915ec7c-d559-4aec-9e51-aed7a5dffc0c',
        group: false,
      },
      {
        id: 'LDC-1-09c46220-4b9d-45b3-b0e7-a5762779460b',
        group: false,
      },
      {
        id: 'LDC-1-d9946506-a40c-4904-88ee-c4d9788781cf',
        group: false,
      },
      {
        id: 'LDC-1-1b2ababb-67b2-4baf-90a4-970947226cf6',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: 'ae6b0c12-f0ea-45e1-9fb8-90097933e28d',
    name: 'VC Top 10 - Greycroft',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-90e99111-7339-47a6-a20e-19e1d6411a0c',
        group: false,
      },
      {
        id: 'LDC-1-c81f8a7c-8ebe-456f-93d4-95c9f64c52ef',
        group: false,
      },
      {
        id: 'LDC-1-4c906933-4cec-47eb-82c4-b92ec23b47a5',
        group: false,
      },
      {
        id: 'LDC-1-69b5d0d7-e207-418d-9054-abce607b783b',
        group: false,
      },
      {
        id: 'LDC-1-094b00f7-a027-44d3-94d9-9ad545eb2ab3',
        group: false,
      },
      {
        id: 'LDC-1-a4380461-95a7-4a05-9fdd-a778500fd8b5',
        group: false,
      },
      {
        id: 'LDC-1-d588f4ec-edc8-4335-84c1-7f44f4d6f791',
        group: false,
      },
      {
        id: 'LDC-1-f5cd7330-da63-49f1-abe3-70733610995e',
        group: false,
      },
      {
        id: 'LDC-1-abee38a9-4113-4750-ae07-24cafc891dd1',
        group: false,
      },
      {
        id: 'LDC-1-9cbe0eea-8b73-4899-b0c6-f44298d57660',
        group: false,
      },
    ],
  },
  {
    itemType: SavedListType.Companies,
    id: '0753d19c-a4ba-46e2-8f35-70ee6c7a8806',
    name: 'VC Top 10 - Upfront',
    createdAt: '2024-11-14T20:59:41Z',
    createdBy: 'support@livedatatechnologies.com',
    items: [
      {
        id: 'LDC-1-b510cc00-7d89-4074-9712-732e83ab9deb',
        group: false,
      },
      {
        id: 'LDC-1-e4864051-d72d-47ab-819e-41ee91422f2a',
        group: false,
      },
      {
        id: 'LDC-1-b598e5e3-7fd1-4469-82ec-433324d5c479',
        group: false,
      },
      {
        id: 'LDC-1-062eee5e-7e0c-4c1b-b699-014de117d477',
        group: false,
      },
      {
        id: 'LDC-1-95178494-8051-412b-bbf8-ca5a7e01726f',
        group: false,
      },
      {
        id: 'LDC-1-91078383-6006-43c3-8ccf-f68ced4efed6',
        group: false,
      },
      {
        id: 'LDC-1-c2dcbc43-4ed3-4cd7-b546-90972f18efe4',
        group: false,
      },
      {
        id: 'LDC-1-528d6b22-605d-49a2-b8e7-d8e55865f2a0',
        group: false,
      },
      {
        id: 'LDC-1-028242d8-e42e-4690-a124-e6cebfe74681',
        group: false,
      },
      {
        id: 'LDC-1-189c8d6d-1417-4b9b-a4c8-6efa2b5c4b2b',
        group: false,
      },
    ],
  },
];
