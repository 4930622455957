<!-- action btns -->
<div *ngIf="canAdmin" class="tw-flex tw-items-center tw-gap-4 tw-flex-row tw-my-4 tw-ml-[.1rem]">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshOrgServiceAccounts()">
    Refresh
  </button>
  <button mat-raised-button color="primary" (click)="showCreateDialog()">
    Create Service Account
  </button>
  <button
    mat-flat-button
    color="warn"
    [disabled]="!rowsSelected"
    (click)="removeSelected()"
    class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
    Remove Selected Service Accounts
  </button>

  <span class="orgId tw-ml-auto"><b>Org ID:</b> {{org.id}}</span>
</div>
<div class="container" width="100%">
  <ag-grid-angular
    style="height: 100%;"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    [tooltipShowDelay]="tooltipShowDelay"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    domLayout="autoHeight"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)">
  </ag-grid-angular>
</div>
