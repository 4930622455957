<div class="tw-flex tw-flex-col tw-min-h-screen tw-bg-gray-100">
  <!-- Header -->
  <header class="tw-bg-gray-800 tw-shadow tw-p-4 tw-flex tw-justify-between tw-items-center">
    <div class="tw-flex tw-items-center tw-flex-wrap">
      <a href="https://www.livedatatechnologies.com">
        <img
          src="https://assets-global.website-files.com/65705b930fb2e670f3206741/657cb4efdeed95030646ede2_Live%20Data%20Logo.svg"
          alt="logo"
          class="tw-h-8 tw-w-auto" />
      </a>
    </div>
    <nav>
      <a href="/" class="tw-text-primary_purple-400 tw-font-semibold tw-flex tw-items-center">
        <mat-icon class="tw-text-[18px] tw-leading-[22px]">home</mat-icon>
        <span class="hover:tw-underline">Back to Homepage</span>
      </a>
    </nav>
  </header>

  <!-- Main Content -->
  <main class="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-4 tw-my-8">
    <div class="onboarding">
      <div class="main-column">
        <app-stepper #cdkStepper [linear]="linear" (gotoStep)="gotoStep($event)">
          <cdk-step label="Profile" [stepControl]="contactFormGroup">
            <div *ngIf="currentStep == 'profile'">
              <div class="header tw-text-gray-800">Personal Information</div>
              <div class="description">To get started, tell us about yourself</div>
              <div class="content">
                <form [formGroup]="contactFormGroup" class="tw-w-full tw-max-w-md">
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="John Doe" formControlName="name" required />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Company</mat-label>
                    <input matInput placeholder="Acme Widgets" formControlName="company" required />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Email Address</mat-label>
                    <input
                      matInput
                      placeholder="bill@microsoft.com"
                      formControlName="emailAddress"
                      required />
                  </mat-form-field>
                  <div class="footer tw-mt-4">
                    <button
                      type="submit"
                      mat-raised-button
                      color="primary"
                      [class.spinner]="continuing"
                      [disabled]="!contactFormGroup.valid || continuing"
                      (click)="nextStep()">
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="currentStep == 'persona'">
              <div class="header">Get onboarded with Live Data</div>
              <div class="description">What are you trying to accomplish?</div>
              <div class="content">
                <form [formGroup]="personaFormGroup">
                  <mat-radio-group formControlName="persona">
                    <mat-radio-button *ngFor="let persona of personas" [value]="persona">
                      <div class="optionHeadline">{{persona.headline}}</div>
                      <div class="optionGoal">{{persona.goal}}</div>
                    </mat-radio-button>
                  </mat-radio-group>
                  <div class="footer">
                    <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                    <button
                      type="submit"
                      mat-raised-button
                      color="primary"
                      [class.spinner]="continuing"
                      [disabled]="!personaFormGroup.valid || continuing"
                      (click)="nextStep()">
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="currentStep == 'goals'">
              <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'investor'">
                <div class="header">Get company insights</div>
                <div class="description">
                  Tell us the companies you care about and we'll deliver insights straight to you
                </div>
                <div class="content">
                  <form [formGroup]="goalFormGroup">
                    <mat-radio-group formControlName="goal">
                      <mat-radio-button *ngFor="let goal of investorGoals" [value]="goal">
                        <div class="optionHeadline">{{goal.headline}}</div>
                        <div class="optionGoal">{{goal.goal}}</div>
                      </mat-radio-button>
                    </mat-radio-group>
                    <div class="footer">
                      <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                      <button
                        type="submit"
                        mat-raised-button
                        color="primary"
                        [class.spinner]="continuing"
                        [disabled]="!goalFormGroup.valid || continuing"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm'">
                <div class="header">Get started with Live Data</div>
                <div class="description">Let us surface your best prospects.</div>
                <div class="content">
                  <form [formGroup]="goalFormGroup">
                    <mat-radio-group formControlName="goal">
                      <mat-radio-button *ngFor="let goal of gtmGoals" [value]="goal">
                        <div class="optionHeadline">{{goal.headline}}</div>
                        <div class="optionGoal">{{goal.goal}}</div>
                      </mat-radio-button>
                    </mat-radio-group>
                    <div class="footer">
                      <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                      <button
                        type="submit"
                        mat-raised-button
                        color="primary"
                        [class.spinner]="continuing"
                        [disabled]="!goalFormGroup.valid || continuing"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'data'">
                <div class="header">For developers and data vendors</div>
                <div class="description">
                  Looking to improve your data quality or incorporate real-time job change data into
                  your offering?
                </div>
                <div class="content">
                  Tell us what you're working on and we'll reach out to chat.
                  <form [formGroup]="dataFormGroup">
                    <mat-form-field>
                      <textarea
                        matInput
                        formControlName="description"
                        placeholder="What are you trying to accomplish?"></textarea>
                    </mat-form-field>
                    <div class="footer">
                      <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                      <button
                        *ngIf="!complete"
                        type="submit"
                        mat-raised-button
                        color="primary"
                        [class.spinner]="continuing"
                        [disabled]="!dataFormGroup.valid || continuing"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </cdk-step>
          <cdk-step label="Details">
            <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'gtm'">
              <div *ngIf="goalFormGroup.get('goal')?.value?.name === 'sample'">
                <div class="header">Who do you sell to?</div>
                <div class="description">
                  Select your target customers by industry, company, function, and level.
                </div>
                <div class="content">
                  <form [formGroup]="sampleFormGroup">
                    <ng-select
                      formControlName="industries"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      placeholder="Select industries...">
                      <ng-option
                        *ngFor="let industry of industries"
                        [value]="industry"
                        >{{industry}}</ng-option
                      >
                    </ng-select>
                    <ng-select
                      formControlName="functions"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      placeholder="Select functions...">
                      <ng-option
                        *ngFor="let function of functions"
                        [value]="function"
                        >{{function}}</ng-option
                      >
                    </ng-select>
                    <ng-select
                      formControlName="levels"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      placeholder="Select levels...">
                      <ng-option *ngFor="let level of levels" [value]="level">{{level}}</ng-option>
                    </ng-select>
                    <div class="footer">
                      <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                      <button
                        *ngIf="goalFormGroup.get('goal')?.value?.name === 'sample'"
                        mat-raised-button
                        color="primary"
                        (click)="nextStep()">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="goalFormGroup.get('goal')?.value?.name === 'upload'">
                <div class="header">Upload a data sample</div>
                <div class="description">
                  Upload up to 500 contacts and see the insights that Live Data offers
                </div>
                <div class="content">
                  <div class="footer">
                    <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                    <flatfile-button
                      [licenseKey]="licenseKey"
                      [settings]="settings"
                      [customer]="customer"
                      [onData]="onData.bind(this)">
                      <div class="ffbutton-text">Upload</div>
                    </flatfile-button>
                  </div>
                </div>
              </div>
            </div>
          </cdk-step>
          <cdk-step label="Welcome!">
            <div *ngIf="!uploadActive && personaFormGroup.get('persona')?.value?.name === 'data'">
              <div class="header">Thank you for your interest!</div>
              <div class="description">We'll be in touch!</div>
              <div class="content">
                Our team will reach out to discuss how Live Data can help you use real-time job
                change data.
                <p>
                  <a href="https://www.livedatatechnologies.com"
                    >In the meantime, continue exploring the value of real-time job change data on
                    our website.</a
                  >
                </p>
                Or, if you want to test our data capabilities now, you can get started with up to
                500 contacts for free.
                <div class="footer">
                  <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                  <flatfile-button
                    [licenseKey]="licenseKey"
                    [settings]="settings"
                    [customer]="customer"
                    [onData]="onData.bind(this)">
                    <div class="ffbutton-text">Upload</div>
                  </flatfile-button>
                </div>
              </div>
            </div>
            <div *ngIf="personaFormGroup.get('persona')?.value?.name === 'investor'">
              <div class="header">Thank You!</div>
              <div class="description">
                Check your email to create your password and get immediate access to the Live Data
                platform.
              </div>
              <div class="content">
                <img src="../assets/img/moneyball-sample.png" alt="sample" />
              </div>
            </div>
            <div *ngIf="uploadActive">
              <div class="header">Analyzing your data sample</div>
              <div
                class="description"
                *ngIf="onboardingStatus?.status != 'failed' && onboardingStatus?.status != 'completed'">
                We are processing your sample. This is what we've found so far.
              </div>
              <div class="description" *ngIf="onboardingStatus?.status == 'completed'">
                Your sample has completed processing.
              </div>
              <div class="description" *ngIf="onboardingStatus?.status == 'failed'">Uh-oh.</div>

              <div class="content">
                <div
                  class="content"
                  *ngIf="onboardingStatus?.status != 'failed' && onboardingStatus?.status != 'completed'">
                  Feel free to stick around to watch the process in real time. But, there's no need
                  to wait here -- we'll email you the results when the sample is complete.
                </div>
                <div class="content" *ngIf="onboardingStatus?.status == 'completed'">
                  Full details, including the changed records, have been emailed to the address
                  provided.
                </div>
                <div class="content" *ngIf="onboardingStatus?.status == 'failed'">
                  Well this is embarassing, but something seems to have gone wrong. Please contact
                  support&#64;livedatatechnologies.com for help. Thanks.
                </div>
                <div
                  class="progress"
                  *ngIf="onboardingStatus?.status != 'completed' && onboardingStatus?.status != 'failed'">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div class="statCardContainer" *ngIf="onboardingStatus?.status != 'failed'">
                  <div class="statCard">
                    <div class="statValue">
                      {{onboardingStatus?.stats?.totalContacts | number:'1.0-0'}}
                    </div>
                    <div class="statHeadline">Total contacts in your sample</div>
                    <div class="statDescription">
                      Once this sample is done, you'll have the opportunity to add more contacts up
                      to the 500 Free limit.
                    </div>
                  </div>
                  <div class="statCard">
                    <div class="statValue">
                      {{onboardingStatus?.stats?.totalContactsFound | number:'1.0-0'}}
                    </div>
                    <div class="statHeadline">Contacts found by Live Data</div>
                    <div class="statDescription">
                      We discovered data on the open web for these people so far. Sometimes we may
                      not find a signal.
                    </div>
                  </div>
                  <div class="statCard">
                    <div class="statValue">
                      {{onboardingStatus?.stats?.totalNewJobs | number:'1.0-0'}}
                    </div>
                    <div class="statHeadline">New Jobs discovered</div>
                    <div class="statDescription">
                      Job changes provide an ideal trigger for reaching out to previous users and
                      customers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="!uploadActive && (personaFormGroup.get('persona')?.value?.name === 'gtm' && goalFormGroup.get('goal')?.value?.name === 'sample')">
              <div class="header">We're building your sample dataset!</div>
              <div class="description">Thank you for providing your ICP information</div>
              <div class="content">
                You will receive an email shortly once your custom data sample is ready.
                <p>
                  <a href="https://www.livedatatechnologies.com"
                    >In the meantime, continue exploring the value of real-time job change data on
                    our website.</a
                  >
                </p>
                Or, if you want to test our data capabilities now, you can get started by uploading
                up to 500 contacts for free.
                <div class="footer">
                  <div (click)="prevStep()"><mat-icon>arrow_back</mat-icon> Back</div>
                  <flatfile-button
                    [licenseKey]="licenseKey"
                    [settings]="settings"
                    [customer]="customer"
                    [onData]="onData.bind(this)">
                    <div class="ffbutton-text">Upload</div>
                  </flatfile-button>
                </div>
              </div>
            </div>
          </cdk-step>
        </app-stepper>
      </div>
    </div>

    <!-- contact us -->
    <section class="tw-font-semibold tw-text-lg tw-mt-auto tw-self-end">
      <span class="tw-text-gray-700 tw-mr-2">Need help?</span>
      <a
        href="javascript:void(0)"
        onclick="window['Intercom']('showNewMessage')"
        class="tw-text-primary_purple-600 hover:tw-underline tw-transition-all">
        Contact us!
      </a>
    </section>
  </main>

  <!-- Footer -->
  <footer
    class="tw-bg-gray-800 tw-text-gray-400 tw-py-4 tw-text-center tw-flex-wrap tw-mt-auto tw-px-4">
    <nav class="tw-flex tw-justify-start tw-space-x-6">
      <a
        href="https://www.livedatatechnologies.com/privacy-policy"
        target="_blank"
        class="tw-flex tw-items-center tw-text-white">
        <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
          Privacy Policy
        </span>
        <mat-icon class="tw-text-base">north_east</mat-icon>
      </a>
      <a
        href="https://www.livedatatechnologies.com/terms-of-service"
        target="_blank"
        class="tw-flex tw-items-center tw-text-white">
        <span class="hover:tw-underline hover:tw-font-semibold tw-transition-all">
          Terms of Use
        </span>
        <mat-icon class="tw-text-base">north_east</mat-icon>
      </a>
    </nav>
  </footer>
</div>
